import { put, select } from 'redux-saga/effects';
import {
  selectIsFetchingStarRatingQuestionSet,
  setIsFetchingStarRatingQuestionSet,
  setStarRatingQuestionSet,
  setStarRatingQuestionSetError,
} from '../../state';
import StarRatingQuestionSets from '../../api-calls/StarRatingQuestionSets';

/**
 * Reset redux states
 * @returns {IterableIterator<*>} next iteration
 */
function* resetState() {
  yield put(setStarRatingQuestionSet(null));
  yield put(setStarRatingQuestionSetError(null));
}

/**
 * Verifies if server is already getting the star rating question set
 * @returns {IterableIterator<*>} next iteration
 */
function* isNotFetchingStarRatingQuestionSet() {
  return yield select(state => !selectIsFetchingStarRatingQuestionSet(state));
}

/**
 * Calls the server API in order to fetch the star rating question set
 * @returns {IterableIterator<Promise|*>} next iteration
 */
function* doServerCall(questionSetId) {
  try {
    const response = yield StarRatingQuestionSets().find(questionSetId);
    yield put(setStarRatingQuestionSet(response.data));
  } catch (e) {
    yield put(setStarRatingQuestionSetError({
      message: e.response.data.message,
      status: e.response.status,
    }));
  }
}

/**
 * Saga to fetch star rating question set
 * @param action
 * @returns {IterableIterator<Promise|*>} next iteration
 */
export default function* fetchStarRatingQuestionSet(action) {
  if (yield* isNotFetchingStarRatingQuestionSet()) {
    yield* resetState();
    yield put(setIsFetchingStarRatingQuestionSet(true));
    yield* doServerCall(action.questionSetId);
    yield put(setIsFetchingStarRatingQuestionSet(false));
  }
}
