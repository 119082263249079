import React from 'react';
import EConsultsQueue from '../econsults-queue/EConsultsQueue';
import EConsult from '../econsult/EConsult';
// import Scheduling from '../scheduling/Scheduling';
// import Availability from '../availability/Availability';
// Simple breadcrumbs use string
// Dynamic breadcrumbs use function/component
const routes = [
  {
    exact: true,
    path: '/',
    breadcrumb: 'eConsults',
    component: <EConsultsQueue statusGroup="SpecialistMobilePending" />,
  },
  {
    exact: false,
    path: '/econsults/:id',
    component: params => (<EConsult eConsultId={params.id} />),
  },
  {
    exact: true,
    path: '/history',
    breadcrumb: 'Request History',
    component: <EConsultsQueue statusGroup="SpecialistMobileAll" />,
  },
  // {
  //   exact: true,
  //   path: '/scheduling',
  //   breadcrumb: 'Scheduling',
  //   component: <Scheduling />,
  // },
  // {
  //   exact: true,
  //   path: '/availability',
  //   breadcrumb: 'Availability',
  //   component: <Availability />,
  // },
];

export default routes;
