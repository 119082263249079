import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectIsUpdatingEConsult } from '../../../../state';

const SubmitButton = ({ isUpdatingEConsult }) => (
  <div className="mt-3 submit-button-container">
    <Button
      color="primary"
      size="lg"
      block
      disabled={isUpdatingEConsult}
      data-auto="specialist-mobile-submit-button"
    >
      {isUpdatingEConsult ? <Spinner color="light" /> : ''}
      <span> Submit </span>
    </Button>
  </div>
);

SubmitButton.propTypes = {
  isUpdatingEConsult: PropTypes.bool,
};

SubmitButton.defaultProps = {
  isUpdatingEConsult: false,
};

const mapStateToProps = state => ({
  isUpdatingEConsult: selectIsUpdatingEConsult(state),
});

export default connect(mapStateToProps)(SubmitButton);
