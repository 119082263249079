import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormGroup, Input } from 'reactstrap';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { errorColor } from '../../../utils/formatters/cssClass';
import ErrorText from '../../common/error-text/ErrorText';

const SpecialistTimeSpent = ({ timeSpentOptions }) => {
  const {
    register,
    errors,
  } = useFormContext();

  return (
    <FormGroup>
      <h5 className={`font-weight-lighter ${errorColor(errors, 'specialist_time_spent')}`}>
              Time spent
      </h5>
      <Input
        type="select"
        name="specialist_time_spent"
        data-auto="specialist-time-spent-select"
        innerRef={register({ required: true })}
        invalid={!isUndefined(errors.specialist_time_spent)}
      >
        <option value="" disabled selected>How much time did you spend on this eConsult?</option>
        {timeSpentOptions.map(option => <option key={option}>{option}</option>)}
      </Input>
      <ErrorText visible={!isUndefined(errors.specialist_time_spent)} className="pt-2 pl-4">
              Required
      </ErrorText>
    </FormGroup>);
};

SpecialistTimeSpent.propTypes = {
  timeSpentOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SpecialistTimeSpent;
