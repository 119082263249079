import APICallBase from './APICallBase';

class VerifySession extends APICallBase {
  constructor() {
    super();
    this.url = '/verify';
  }
  /**
   * Get the verication of access service
   * @param service
   * @return {Promise} endpoint call promise
   */
  verifyAccess(service) {
    return this.get(null, service).call().then(this.sortOrganizations, error => error);
  }

  /**
   * Sort the organizations
   * @param response
   * @return {response}  response data organizations sorted
   */
  #sortOrganizations = response => response.data.organizations.sort((a, b) => {
    if (a.display_name === b.display_name) return 0;
    if (a.display_name < b.display_name) return -1;
    if (a.display_name > b.display_name) return 1;
    return null;
  })
}

export default () => new VerifySession();
