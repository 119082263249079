import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { userInitials } from '../../../utils/formatters/econsults';

/**
 * Returns the user's profile picture in a circle format or returns its initials according to the
 * design
 * @param photoUrl user's profile photo url
 * @param firstName user's first name in order to build initials
 * @param lastName user's last name in order to build initials
 * @returns {*}
 * @constructor
 */
const UserPhoto = ({ photoUrl, firstName, lastName }) => {
  const initials = userInitials(firstName, lastName);
  return (
    <div className="d-inline-block" data-auto="user-photo">
      {
        (!_.isNull(photoUrl) && photoUrl !== 'img/person.png') ?
          <img width="80" className="rounded-circle" src={photoUrl} alt={initials} />
          : <div className="rounded-circle p-3 user-initials">{initials}</div>
      }
    </div>
  );
};

UserPhoto.propTypes = {
  photoUrl: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

UserPhoto.defaultProps = {
  photoUrl: 'img/person.png',
};

export default UserPhoto;
