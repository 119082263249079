import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/**
 * Idle modal open/close is controlled by Session idleMonitor
 */
const ConfirmationModal = ({
  toggle,
  isOpen,
  modalHeaderContent,
  className,
  modalBodyContent,
  modalButtons,
}) => (
  <Modal className="aristamd-modal" isOpen={isOpen} toggle={toggle} backdrop="static" data-auto="confirmation-modal">
    <ModalHeader className={className} data-auto="confirmation-modal-header">{modalHeaderContent}</ModalHeader>
    <ModalBody data-auto="confirmation-modal-body">
      {modalBodyContent}
    </ModalBody>
    <ModalFooter data-auto="confirmation-modal-footer">
      {modalButtons.cancel ? <div className="mr-auto"><Button color={modalButtons.cancel.color} onClick={toggle} data-auto="confirmation-modal-cancel-button">{modalButtons.cancel.text}</Button> </div> : null }
      <div><Button color={modalButtons.ok.color} onClick={modalButtons.ok.onClick} data-auto="confirmation-modal-ok-button">{modalButtons.ok.text}</Button></div>
    </ModalFooter>
  </Modal>
);

ConfirmationModal.propTypes = {
  modalHeaderContent: PropTypes.string.isRequired,
  modalBodyContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  modalButtons: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

ConfirmationModal.defaultProps = {
  className: '',
  toggle: () => {},
};

export default ConfirmationModal;
