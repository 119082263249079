import SPApiCallBase from './SPApiCallBase';
import Session from '../../services/Session';
import PractitionerParser from '../../utils/fhir-parsers/Practitioner';

class Practitioner extends SPApiCallBase {
  url = '/Practitioner';

  /**
   * Retrieves the current user's practitioner info in SP
   * @return {Promise<{}>}
   */
  getCurrentPractitioner() {
    return this.get({ _filter: `user_id eq ${Session.userId}` }).call()
      .then(({ data }) => Promise.resolve(PractitionerParser(data.contained[0]).transform()));
  }
}

export default () => new Practitioner();
