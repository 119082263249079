import { put } from 'redux-saga/effects';
import cookie from 'react-cookies';
import {
  fetchUser,
  isVerifyingAccess,
  setUserOrganizations,
  setUserOrganizationId,
} from '../state';
import Session from '../services/Session';
import { updateOrganization } from '../services/axios';
import VerifySession from '../api-calls/VerifySession';
import EnvironmentSettings from '../services/EnvironmentSettings';

export default function* verifyAccess() {
  yield put(isVerifyingAccess(true));
  try {
    const response = yield VerifySession().verifyAccess('specialist_mobile');
    const status = response?.response?.status || null;
    // If is 417 Expectation Failed, let's return
    // The axios.js interceptors will handle the redirect
    if(status === 417){
      return;
    }

    yield put(setUserOrganizations(response.data.organizations));
    if (response.data.organizations.length === 1) {
      yield put(setUserOrganizationId(response.data.organizations[0].id));
      cookie.save(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, response.data.organizations[0].id);
      updateOrganization(response.data.organizations[0].id);
    } else {
      const orgId = cookie.load(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
      if (orgId && response.data.organizations.find(org => org.id === orgId) !== false) {
        yield put(setUserOrganizationId(orgId));
        updateOrganization(orgId);
      }
    }
    yield put(fetchUser(Session.userId));
  } catch (e) {
    yield put(setUserOrganizations(null));
    yield put(setUserOrganizationId(null));
    let error = '';
    if (e.response && e.response.data && e.response.data.error) {
      ({ error } = e.response.data);
    }
    Session.unauthorized(error);
  }
  // Restore original state
  yield put(isVerifyingAccess(false));
}
