// Parse the current domain and set the environment settings accordingly.
// For example the following domains would parse as follows:
//   app.aristamd.com -> ["app.aristamd.com", "app", undefined, "aristamd.com"]
//   client1.test.aristamd.com" -> ["client1.test.aristamd.com", "client1", "test", "aristamd.com"]
const DOMAIN = window.location.hostname.split('.').slice(-2).join('.');
const url = window.location.host
  .replace(`.${DOMAIN}`, '')
  .replace('https://', '')
  .replace('www.', '');
const hostParts = url.split('.').reverse();
const stage = hostParts[0] === 'specialist' ? '' : `.${hostParts[0]}`;
const EnvironmentSettings = {
  stage,
  domainServer: `${stage}.${DOMAIN}`,
  assetsServer: `https://specialist${stage}.${DOMAIN}/assets`,
  clientId: '1',
  clientSecret: 'fultm56nuQZW2VSJYci9rTU9sikip1gnDQSiUmJl',
  timezone: 'America/Los_Angeles',
};

export default EnvironmentSettings;
