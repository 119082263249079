import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../common/loading/Loading';
import SelectSite from '../select-site/SelectSite';
import { selectUser, selectUserOrganizationId, selectUserOrganizations } from '../../state';

const Setup = ({ user, organizations, organizationId }) => (
  <div className="setup-wrapper">
    <img src="/images/aristamd_logo.png" height={60} alt="AristaMD" />
    <div className="module">
      {user.id && organizations && organizationId === -1 ? <SelectSite /> : <Loading />}
    </div>
  </div>
);

Setup.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    display_name: PropTypes.string,
  })),
  organizationId: PropTypes.number,
};

Setup.defaultProps = {
  user: {},
  organizations: [],
  organizationId: -1,
};

const mapStateToProps = state => ({
  user: selectUser(state),
  organizations: selectUserOrganizations(state),
  organizationId: selectUserOrganizationId(state),
});

export default connect(mapStateToProps)(Setup);
