import { takeEvery } from 'redux-saga/effects';
import fetchEConsults from './fetchEConsults';
import fetchEConsult from './fetchEConsult';
import updateEConsult from './updateEConsult';

const setSagaNamespace = sagaName => `econsultsSaga/${sagaName}`;

export default function* econsultsSaga() {
  return [
    yield takeEvery(setSagaNamespace('FETCH_ECONSULTS'), fetchEConsults),
    yield takeEvery(setSagaNamespace('FETCH_ECONSULT'), fetchEConsult),
    yield takeEvery(setSagaNamespace('UPDATE_ECONSULT'), updateEConsult),
  ];
}
