import React from 'react';
import PropTypes from 'prop-types';
import PreviousEConsultsBody from './previous-econsults-body/PreviousEConsultsBody';
import UpperCollapsibleSection
  from '../../common/upper-collapsible-section/UpperCollapsibleSection';

const PreviousEConsults = ({ eConsult }) => {
  return (
    <UpperCollapsibleSection
      getTitle={showDetails =>
        `${!showDetails ? 'Show' : 'Hide'} Previous eConsults `
      }
      dataAut="previous-econsults-collapsible-link"
      showDetails
    >
      <PreviousEConsultsBody
        eConsult={eConsult}
      />
    </UpperCollapsibleSection>
  );
};

PreviousEConsults.propTypes = {
  eConsult: PropTypes.shape({
    patient_name: PropTypes.string.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default PreviousEConsults;
