import { all, select, put } from 'redux-saga/effects';
import Group from '../../api-calls/sp/Group';
import Schedule from '../../api-calls/sp/Schedule';
import GroupParser from '../../utils/fhir-parsers/Group';
import BundleParser from '../../utils/fhir-parsers/Bundle';
import {
  selectPractitioner,
  setPractitionerScheduleError,
  setIsFetchingPractitionerSchedule,
  setPractitionerScheduleData,
  setFhirPractitionerScheduleDates,
} from '../../state';

export default function* fetchPractitionerSchedule(action) {
  try {
    const practitioner = yield select(state => selectPractitioner(state));
    let [panel, scheduleDates] = yield all([
      yield Group().find(practitioner.panelId, { type: 'Practitioner', _elements: 'extension' }),
      yield Schedule().getScheduleDates(practitioner.schedule.id),
    ]);
    yield put(setFhirPractitionerScheduleDates(scheduleDates.data));
    panel = GroupParser(panel.data).transform();
    scheduleDates = BundleParser(scheduleDates.data).transform().data;
    const data = action?.buildScheduleData(panel, scheduleDates) || { panel, scheduleDates };
    yield put(setPractitionerScheduleData(data));
    yield put(setIsFetchingPractitionerSchedule(false));
  } catch (e) {
    yield put(setPractitionerScheduleError(true));
    yield put(setIsFetchingPractitionerSchedule(false));
  }
}
