import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import SeparatorTitle from '../../common/separator-title/SeparatorTitle';
import { dateWithTimezoneFormat } from '../../../utils/formatters/date';

const StatusUpdate = ({
  updatedAt,
  iconClass,
  text,
  textEmphasis,
}) => {
  const updatedAtFormatted = dateWithTimezoneFormat(updatedAt);
  return (
    <Row>
      <Col data-auto="status-update">
        <SeparatorTitle title="Update" isLocked />
        <p className="text-center font-size-smaller" data-auto="updated-at-text">
          {updatedAtFormatted}
        </p>
        <Row>
          <Col className="d-flex justify-content-center m-3">
            <div className="rounded-circle p-3 badge-primary">
              <i className={`icon-5 ${iconClass}`} data-auto="update-icon" />
            </div>
          </Col>
        </Row>
        <p className="text-center m-0 font-weight-lighter font-size-large" data-auto="text-field">
          {text}
        </p>
        <p className="text-center font-size-large" data-auto="text-emphasis-field">
          {textEmphasis}
        </p>
      </Col>
    </Row>
  );
};

StatusUpdate.propTypes = {
  updatedAt: PropTypes.shape({
    date: PropTypes.string,
  }).isRequired,
  iconClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textEmphasis: PropTypes.string,
};

StatusUpdate.defaultProps = {
  textEmphasis: '',
};

export default StatusUpdate;
