import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Container, Row, Button } from 'reactstrap';

/**
 * General use loading spinner component which can automatically load content and display an error
 * with reload button if the content fails to load
 * @param {string} size spinner size (lg, md)
 * @param {function} loadFunc function to automatically load and reload content
 * @param {boolean} isReloadable set to true to display a reload button if content fails to load
 * @param {boolean} isLoaded set to true if content has been loaded. Hides spinner and shows content
 * @param {boolean} isError set to true if content fails to load. Displays an error message
 * @param {string} errorMessage custom error message to display if content fails to load
 * @param {element|array} children body content
 * @returns {*|JSX.Element}
 * @constructor
 */
const Loading = ({
  size,
  loadFunc,
  isReloadable,
  isLoaded,
  isError,
  errorMessage,
  children,
}) => {
  // Run effect on first render
  useEffect(() => {
    // If content not loaded and load function was provided, call the load function
    if (!isLoaded && loadFunc) {
      loadFunc();
    }
  }, []);

  return isLoaded ? children :
  <Container className={`loading-container-${size}`} data-auto="loading-container">
    <Row className="justify-content-center align-items-center">
      {isError ?
        <div className="text-center" data-auto="loading-error">
          <p data-auto="loading-error-text">{ errorMessage || "Something didn't load correctly." }</p>
          {loadFunc && isReloadable ? <Button color="primary" size="md" onClick={loadFunc} data-auto="loading-reload-button">Reload</Button> : ''}
        </div> :
        <Spinner className="loading-spinner" data-auto="loading-spinner" color="primary" size={size} />
      }
    </Row>
  </Container>;
};

const {
  array,
  element,
  oneOfType,
} = PropTypes;

Loading.propTypes = {
  size: PropTypes.string,
  loadFunc: PropTypes.func,
  isReloadable: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: oneOfType([element, array]),
};

Loading.defaultProps = {
  size: 'lg',
  loadFunc: null,
  isReloadable: false,
  isLoaded: false,
  isError: false,
  errorMessage: null,
  children: null,
};

export default Loading;
