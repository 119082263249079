import { Col, Container, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import {
  dateFormat,
  timeFormat,
} from '../../../utils/formatters/date';


const FullDetailsDesktop = ({ eConsultDetails }) => (
  <div className="full-details font-size-larger">
    <Container>
      <Row>
        <h4 className="font-weight-bold mb-4">Full Request Details</h4>
      </Row>
      <Row>
        <h5 className="font-size-larger">Patient Information</h5>
      </Row>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">AristaMD ID</p>
        </Col>
        <Col xs="10" className="p-0">
          <p className="m-0" data-auth="full-details-aristamd-id" >{eConsultDetails.patient_id}</p>
        </Col>
      </Row>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Insurance</p>
        </Col>
        <Col xs="10" className="p-0">
          <p className="m-0" data-auth="full-details-mrn">{eConsultDetails.insurance}</p>
        </Col>
      </Row>
    </Container>
    <Container className="mt-4">
      <Row className="p-1">
        <h5 className="font-size-larger">Requesting Clinic</h5>
      </Row>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Organization</p>
        </Col>
        <Col xs="10" className="p-0">
          <p className="m-0" data-auth="full-details-organization">{eConsultDetails.organization}</p>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Site</p>
        </Col>
        <Col xs="10" className="p-0">
          <p className="m-0" data-auth="full-details-site">{eConsultDetails.clinic}</p>
        </Col>
      </Row>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Notes</p>
        </Col>
        <Col xs="10" className="p-0" >
          <p className="m-0" data-auth="full-details-notes">{eConsultDetails.notes}</p>
        </Col>
      </Row>
    </Container>
    <Container className="mt-4">
      <Row className="p-1">
        <h5 className="font-size-larger" > Assignment Info</h5>
      </Row >
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Service Type</p>
        </Col>
        <Col xs="10" className="p-0" >
          <p className="m-0">eConsult</p>
        </Col>
      </Row>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Request ID</p>
        </Col>
        <Col xs="10" className="p-0" >
          <p className="m-0" data-auth="full-details-econsult-masked-id">{eConsultDetails.masked_id}</p>
        </Col>
      </Row>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Assignment Date</p>
        </Col>
        <Col xs="10" className="p-0" >
          <p className="m-0" data-auth="full-details-assignment-date">{dateFormat(eConsultDetails.assignment_date)} at
            &nbsp;{timeFormat(eConsultDetails.assignment_date)}
          </p>
        </Col>
      </Row>
      <Row className="p-1">
        <Col xs="2" className="pl-0">
          <p className="font-weight-lighter m-0">Response Deadline</p>
        </Col>
        <Col xs="10" className="p-0" >
          <p className="m-0" data-auth="full-details-disposition-due-date">
            {dateFormat(eConsultDetails.disposition_due_date)} at
            &nbsp;{timeFormat(eConsultDetails.disposition_due_date)}
          </p>
        </Col>
      </Row>
    </Container>
  </div>);

FullDetailsDesktop.propTypes = {
  eConsultDetails: PropTypes.shape({
    date_of_birth: PropTypes.string.isRequired,
    patient_id: PropTypes.number.isRequired,
    mrn: PropTypes.string.isRequired,
    organization: PropTypes.string.isRequired,
    clinic: PropTypes.string.isRequired,
    masked_id: PropTypes.string.isRequired,
    notes: PropTypes.string,
    assignment_date: PropTypes.string.isRequired,
    disposition_due_date: PropTypes.string.isRequired,
  }),
};

FullDetailsDesktop.defaultProps = {
  eConsultDetails: {},
};

export default FullDetailsDesktop;
