import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import PropTypes from 'prop-types';
import routes from '../main-page/routes';

// map & render your breadcrumb components however you want.
const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/'] });
    return (
        <div className="breadcrumbs-container">
            <div
                className="breadcrumbs-bar container"
            >
                <Breadcrumb tag="nav">
                    {breadcrumbs.map(({
                                          match,
                                          breadcrumb,
                                          key,
                                          // other props are available during render, such as `location`
                                          // and any props found in your route objects will be passed through too
                                      }, index) => (
                        <BreadcrumbItem active={index === breadcrumbs.length - 1} key={key} >
                            {index < breadcrumbs.length - 1 ? <NavLink to={match.url}>{breadcrumb}</NavLink> :
                                breadcrumb
                            }
                        </BreadcrumbItem>
                    ))}
                </Breadcrumb>
            </div>
        </div>
    )
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    breadcrumb: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    key: PropTypes.string.isRequired,
  })),
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

export default Breadcrumbs;
