import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import ErrorText from '../../common/error-text/ErrorText';


import { isDisabled, isInvalid } from '../../../utils/formatters/cssClass';

const options = [
  { value: 'routine', label: 'Routine - Patient can be scheduled accordingly.' },
  { value: 'weekly', label: 'Urgent – Patient visit should be expedited' },
  { value: 'emergent', label: 'Emergent - Patient needs immediate medical care.' },
];

const VisitUrgencySelector = ({
  disabled,
  className,
  name,
  invalid,
  value,
  dataAut,
  clearAfterDisable,
}) => {
  /**
   * React hook form instance from parent component
   */
  const {
    setValue,
    register,
    errors,
    clearErrors,
    formState,
  } = useFormContext();

  /**
   * Defines What is the current selected option in the dropdown
   */
  const [currentOption, setCurrentOption] = useState(null);

  useEffect(() => {
      register({ name: 'visit_urgency' }, {
          validate: (valueToValidate) => {
              if (!disabled) {
                  return !_.isEmpty(valueToValidate);
              }
              return true;
          }
      });
      // In case the dropdown is disabled the current option must be cleared
      if ( disabled && clearAfterDisable) {
          setValue(name, null, { shouldValidate: formState.isSubmitted });
          setCurrentOption(null);
          clearErrors('visit_urgency');
      }
      // // Sets the value in the dropdown selector
      setCurrentOption(options.find(option => option.value === value) || null);
  }, [disabled]);

  /**
   * This method is called when there is a change in the dropdown in order to set the visit urgency
   * @param event
   */
  const setUrgencyOption = (event) => {
    // Sets the current option inside state
    setCurrentOption(event);
    // Sets the current option in the form hook object
    setValue(name, event.value, { shouldValidate: formState.isSubmitted });
    // Clears any error related with the visit field in the form hook object
    if (errors[name]) {
      clearErrors(name);
    }
  };

  const isInvalidClass = isInvalid(invalid);
  const isDisabledClass = isDisabled(disabled);

  return (
    <div className="pb-3 visit-urgency-container">
      <Select
        options={options}
        placeholder="Select Urgency"
        isDisabled={disabled}
        className={`${className} react-select${isInvalidClass}${isDisabledClass}`}
        name={name}
        value={currentOption}
        data-auto={dataAut}
        onChange={setUrgencyOption}
      />
      <ErrorText
        visible={!_.isUndefined(errors.visit_urgency)}
        className="pt-2 recommended-speciality-error-message"
      >Required
      </ErrorText>
    </div>
  );
};

VisitUrgencySelector.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  dataAut: PropTypes.string,
  value: PropTypes.string,
  clearAfterDisable: PropTypes.bool,
};

VisitUrgencySelector.defaultProps = {
  className: 'specialty-selector',
  disabled: false,
  value: null,
  dataAut: 'visit-urgency',
  clearAfterDisable: true,
};

export default VisitUrgencySelector;
