import React from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  formatPatientGender,
  formatAge,
  formatSupportingDetails,
} from '../../../utils/formatters/econsults';
import store from '../../../store';
import TimeRemaining from '../../time-remaining/TimeRemaining';
import { setEConsult, setIsSideDrawerOpen, setPreviousPath } from '../../../state';
import { lastUpdate } from '../../../utils/formatters/date';
import Uppy from '../../../services/Uppy';


const EConsultQueueItem = ({ item, history }) => {
  const goToEConsult = () => {
    Uppy.reset();
    store.dispatch(setPreviousPath(history.location.pathname));
    store.dispatch(setEConsult(null));
    history.push(`/econsults/${item.id}`);
    store.dispatch(setIsSideDrawerOpen(false));
  };

  const lastTimeUpdate = () => {
    if (item.disposition_due_date !== null && item.assigned_to_panelist) {
      return <TimeRemaining dueDate={item.disposition_due_date} />;
    }
    return lastUpdate(item.updated_at);
  };
  const lastUpdateTime = lastTimeUpdate();
  const age = formatAge(item.patient_age_in_days);
  const gender = formatPatientGender(item.patient_gender);
  const supportingDetails = formatSupportingDetails(item.main_question);
  return (
    <div
      className="econsult-item"
      onClick={goToEConsult}
      role="presentation"
      data-auto={`econsult-item-${item.id}`}
    >
      <Row >
        <Col xs="8">
          <h4 className="patient-name " data-auto="econsult-patient-name">
            <span className="text-truncate text-overflow">{item.patient_name}</span>
            <span className="d-none d-xl-inline d-lg-line"> &ndash; </span>
            <span>{age}, {gender}</span>
          </h4>
        </Col>
        <Col xs="4 text-right font-size-small">
          <span data-auto="econsult-last-update" >{lastUpdateTime}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="supporting-details" data-auto="econsult-supporting-details">
            {supportingDetails}
          </p>
        </Col>
      </Row>
    </div>
  );
};

EConsultQueueItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    disposition_due_date: PropTypes.oneOfType([PropTypes.string, Date]),
    updated_at: PropTypes.string,
    masked_id: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    patient_gender: PropTypes.string.isRequired,
    main_question: PropTypes.string.isRequired,
    assigned_to_panelist: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(EConsultQueueItem);
