import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */
export const FETCH_USER = 'user/FETCH_USER';
export const IS_FETCHING_USER = 'user/IS_FETCHING_USER';
export const IS_LOGGING_OUT = 'user/IS_LOGGING_OUT';
export const USER = 'user/USER';
export const USER_ORGANIZATIONS = 'user/ORGANIZATIONS';
export const USER_ORGANIZATION_ID = 'user/ORGANIZATION_ID';
export const VERIFY_ACCESS = 'user/VERIFY_ACCESS';
export const IS_VERIFYING_ACCESS = 'user/IS_VERIFYING_ACCESS';
export const ACCESS_ERROR = 'user/ACCESS_ERROR';
export const USER_ERROR = 'user/USER_ERROR';
export const USER_DEVICE_AUTHORIZED = 'user/USER_DEVICE_AUTHORIZED';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateUser = {
  isFetchingUser: false,
  isLoggingOut: false,
  isVerifyingAccess: false,
  user: {
    id: null,
    full_name: null,
  },
  organizations: null,
  organizationId: null,
  userError: null,
  userDeviceAuthorized: false,
};
export default createReducer(initialStateUser, {
  [FETCH_USER]: (state, { payload }) => ({
    ...state, fetchUser: payload,
  }),
  [IS_FETCHING_USER]: (state, { payload }) => ({
    ...state, isFetchingUser: payload,
  }),
  [IS_LOGGING_OUT]: (state, { payload }) => ({
    ...state, isLoggingOut: payload,
  }),
  [USER]: (state, { payload }) => ({
    ...state, user: payload,
  }),
  [IS_VERIFYING_ACCESS]: (state, { payload }) => ({
    ...state, isVerifyingAccess: payload,
  }),
  [USER_ORGANIZATIONS]: (state, { payload }) => ({
    ...state, organizations: payload,
  }),
  [USER_ORGANIZATION_ID]: (state, { payload }) => ({
    ...state, organizationId: payload,
  }),
  [USER_ERROR]: (state, { payload }) => ({ userError: payload }),
  [USER_DEVICE_AUTHORIZED]: (state, { payload }) => ({ userDeviceAuthorized: payload }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches a user given an id
 * @param {int} id
 * @returns {{type: string, payload: int}}
 */
export const fetchUser = userId => ({
  type: FETCH_USER,
  payload: userId,
});

/**
 *
 * @param {array} Users
 * @return {{type: string, payload: *}}
 */
export const setUser = user => ({
  type: USER,
  payload: user,
});

/**
 * Used by the UI to determine if the page is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isFetchingUser = bool => ({
  type: IS_FETCHING_USER,
  payload: bool,
});

/**
 * Used by the UI to determine if the user is logging out
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isLoggingOut = bool => ({
  type: IS_LOGGING_OUT,
  payload: bool,
});

/**
 * @param {integer} id
 * @returns {{type: string, payload: integer}}
 */
export const setUserOrganizationId = id => ({
  type: USER_ORGANIZATION_ID,
  payload: id,
});

/**
 * @param {[]} orgs
 * @returns {{type: string, payload: []}}
 */
export const setUserOrganizations = orgs => ({
  type: USER_ORGANIZATIONS,
  payload: orgs,
});

/**
 * @param {int} id
 * @returns {{type: string}}
 */
export const verifyAccess = () => ({
  type: VERIFY_ACCESS,
});

/**
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isVerifyingAccess = bool => ({
  type: IS_VERIFYING_ACCESS,
  payload: bool,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setUserError = userError => ({
  type: USER_ERROR,
  payload: userError,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setUserDeviceAuthorized = deviceAuthorized => ({
  type: USER_DEVICE_AUTHORIZED,
  payload: deviceAuthorized,
});

/** ********************************************************
 * SELECTORS
 ******************************************************** */
/**
 * @param state
 * @returns {boolean}
 */
export const selectIsFetchingUser = state => state.user.isFetchingUser;

/**
 * @param state
 * @returns {boolean}
 */
export const selectIsLoggingOut = state => state.user.isLoggingOut;

/**
 * @param state
 * @returns {boolean}
 */
export const selectUser = state => state.user.user;

/**
 * @param state
 * @returns {boolean}
 */
export const selectFetchUser = state => state.user.fetchUser;

/**
 * @param state
 * @returns {boolean}
 */
export const selectIsVerifyingAccess = state => state.user.isVerifyingAccess;

/**
 * @param state
 * @returns []
 */
export const selectUserOrganizations = state => state.user.organizations;

/**
 * @param state
 * @returns {integer}
 */
export const selectUserOrganizationId = state => state.user.organizationId;

/**
 * @param state
 * @return {boolean}
 */
export const selectUserError = state => state.user.userError;

/**
 * @param state
 * @return {boolean}
 */
export const selectUserDeviceAuthorized = state => state.user.userDeviceAuthorized;
