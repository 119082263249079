import { put, select, all } from 'redux-saga/effects';
import EConsults from '../../api-calls/EConsults';

import {
  setEConsults,
  setIsFetchingEConsults,
  selectIsFetchingEConsults,
  setEConsultsError,
  selectCurrentPage,
  setCurrentPage,
  setHasMore,
  selectHasMore,
  selectEConsults,
  selectEConsultsError,
} from '../../state';

/**
 * What is the default limit used in the server calls
 * @type {number}
 */
const DEFAULT_LIMIT = 10;

/**
 * This generator function is in charge to update the redux state in order to reset the values
 * @returns {IterableIterator<*>} next execution
 */
function* resetStates() {
  yield put(setEConsults([]));
  yield put(setHasMore(true));
  yield put(setCurrentPage(1));
  yield put(setEConsultsError(null));
}

/**
 * Retrieves the current value of the state
 * @returns {IterableIterator<*>} next execution
 */
function* selectCurrentStateValues() {
  return yield all([
    select(state => selectIsFetchingEConsults(state)),
    select(state => selectHasMore(state)),
    select(state => selectEConsultsError(state)),
    select(state => selectCurrentPage(state)),
  ]);
}

/**
 * Updates the queue content adding new elements and increasing the queue page
 * @param newEConsults new records to append to the queue
 * @param currentPage current page Number
 * @returns {IterableIterator<*>} next execution
 */
function* updateQueueContent(newEConsults, currentPage) {
  const currentEConsults = yield select(state => selectEConsults(state));
  yield put(setCurrentPage(currentPage + 1));
  yield put(setEConsults([...currentEConsults, ...newEConsults]));
}

/**
 * Sets a flag in the state that indicates the end of the queue (all the available data was fetched)
 * @returns {IterableIterator<*>} next execution
 */
function* setQueueEnd() {
  yield put(setHasMore(false));
}

/**
 * Once the saga has ended executes a complete callback
 * @param completeCallback complete callback
 * @param errorValue if there was an error clears the error value
 * @returns {IterableIterator<*>} next execution
 */
function* completeOperation(completeCallback, errorValue) {
  if (errorValue) {
    yield put(setEConsultsError(null));
  }
  completeCallback();
}

/**
 * Fetch eConsults main saga
 * @param action saga input parameters
 * @returns {IterableIterator<IterableIterator<*>|*>} next saga execution
 */
export default function* fetchEConsults(action) {
  if (action.reset) {
    yield* resetStates();
  }
  const [isFetchingEConsults, hasMore, error, currentPage] =
    yield* selectCurrentStateValues();
  if (!isFetchingEConsults && hasMore && !error) {
    yield put(setIsFetchingEConsults(true));
    try {
      const response = yield EConsults().get({ statusGroup: action.statusGroup, currentUser: 'true' })
        .getPage(currentPage, DEFAULT_LIMIT).call();
      yield* response.data.length !== 0 ?
        updateQueueContent(response.data, currentPage) : setQueueEnd();
    } catch (e) {
      yield put(setEConsultsError(e.message));
    }
    yield put(setIsFetchingEConsults(false));
  }
  if (action.completed) {
    yield* completeOperation(action.completed, error);
  }
}
