import { Col, Container, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import EconsultModalAttachment from '../econsult-attachment-modal/EconsultModalAttachment';


const AttachmentsBody = ({ eConsult, desktopVersion }) => (
  <Container className="p-0">
    <Row className="p-2">
      <Col>
        {desktopVersion ?
          <h4 className="font-weight-bolder mb-4">Attachments</h4> :
          <h5 className="font-weight-lighter">Attachments</h5>}
        <div className={desktopVersion ? 'attachments-container' : 'attachments-container p-0'} data-auto="attachments-container">
          {eConsult.attachments.length !== 0 ? (
            eConsult.attachments.map(attachment => (
              <EconsultModalAttachment
                key={`attachment-container-${attachment.id}`}
                attachment={attachment}
                eConsult={eConsult}
                desktopVersion={desktopVersion}
              />
            ))
          ) :
          (<p className={desktopVersion ? 'font-size-larger' : ''}>No attachments added</p>)}
        </div>
      </Col>
    </Row>
  </Container>
);

AttachmentsBody.propTypes = {
  eConsult: PropTypes.shape({
    patient_name: PropTypes.string.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  desktopVersion: PropTypes.bool,
};

AttachmentsBody.defaultProps = {
  eConsult: {},
  desktopVersion: false,
};

export default AttachmentsBody;
