import createReducer from './createReducer';

/** ********************************************
 * constants
 ******************************************** */

export const ECONSULT = 'eConsult/ECONSULT';
export const IS_FETCHING_ECONSULT = 'eConsult/IS_FETCHING_ECONSULT';
export const IS_UPDATING_ECONSULT = 'eConsult/IS_UPDATING_ECONSULT';
export const ECONSULT_ERROR = 'eConsult/ECONSULT_ERROR';

/** ********************************************
 * reducer
 ******************************************** */

export const initialEConsultState = {
  eConsult: null,
  isFetchingEConsult: false,
  isUpdatingEConsult: false,
  eConsultError: null,
};

export default createReducer(initialEConsultState, {
  [ECONSULT]: (state, { payload }) => ({ eConsult: payload }),
  [IS_FETCHING_ECONSULT]: (state, { payload }) => ({ isFetchingEConsult: payload }),
  [IS_UPDATING_ECONSULT]: (state, { payload }) => ({ isUpdatingEConsult: payload }),
  [ECONSULT_ERROR]: (state, { payload }) => ({ eConsultError: payload }),
});

/** ********************************************
 * actions
 ******************************************** */

/**
 *
 * @param {array} eConsults
 * @return {{type: string, payload: *}}
 */
export const setEConsult = eConsult => ({
  type: ECONSULT,
  payload: eConsult,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setIsFetchingEConsult = bool => ({
  type: IS_FETCHING_ECONSULT,
  payload: bool,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setIsUpdatingEConsult = bool => ({
  type: IS_UPDATING_ECONSULT,
  payload: bool,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setEConsultError = eConsultError => ({
  type: ECONSULT_ERROR,
  payload: eConsultError,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {boolean}
 */
export const selectEConsult = state => state.eConsult.eConsult;

/**
 * @param state
 * @return {boolean}
 */
export const selectIsFetchingEConsult = state => state.eConsult.isFetchingEConsult;

/**
 * @param state
 * @return {boolean}
 */
export const selectIsUpdatingEConsult = state => state.eConsult.isUpdatingEConsult;

/**
 * @param state
 * @return {boolean}
 */
export const selectEConsultError = state => state.eConsult.eConsultError;
