import React from 'react';
import { Card, CardTitle, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const Error = ({ message, title }) => (
  <div className="container">
    <div className="justify-content-center row">
      <div className="col error-message-container">
        <Card body inverse className="danger-message-container">
          <Row>
            <Col xs={2}>
              <i className="fa fa-times-circle-o error-icon pt-1 pl-2" />
            </Col>
            <Col xs={10}>
              <CardTitle data-auto="specialist-mobile-error-request-not-found" className="danger-message-text">{title}</CardTitle>
              <div data-auto="specialist-mobile-error-contact-support" className="danger-message-text">{message}</div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  </div>
);

Error.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string,
};

Error.defaultProps = {
  title: 'Error Found',
};

export default Error;
