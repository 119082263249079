import SPApiCallBase from './SPApiCallBase';

class Schedule extends SPApiCallBase {
  url = '/Schedule';

  /**
   * Gets all the scheduled dates inside a schedule
   * @param scheduleId
   * @return {Promise}
   */
  getScheduleDates(scheduleId) {
    return this.get(null, `${scheduleId}/$dates`).call();
  }

  /**
   * Post the schedule dates for a specified schedule
   * @param scheduleId
   * @param body
   * @return {Promise} endpoint call promise
   */
  postScheduleDates(scheduleId, body) {
    this.url = `${this.url}/${scheduleId}/$dates`;
    return this.post(body);
  }
}

export default () => new Schedule();
