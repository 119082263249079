import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import RequestBody from '../request-body/RequestBody';
import AttachmentsBody from '../attachments-body/AttachmentsBody';
import FullDetails from '../desktop-experience/FullDetailsDesktopExperience';
import PreviousEConsults from '../previous-econsults/previous-econsults-body/PreviousEConsultsBody';
import ResponseBody from '../response-body/ResponseBody';
import EConsultEvents from '../econsult-events/EConsultEvents';


const DesktopExperience = ({ eConsult }) => (
  <Container className="econsult-desktop-container pt-4" >
    <Row>
      <Col className="pr-5 pl-5 desktop-section" >
        <h4 className="desktopSubHead font-weight-bold text-primary">eConsult Request</h4>
        <RequestBody
          eConsult={eConsult}
          desktopVersion
        />
        <AttachmentsBody
          eConsult={eConsult}
          desktopVersion
        />
        <PreviousEConsults
          eConsult={eConsult}
          desktopVersion
        />
        <div className="pt-4 pb-4">
          <FullDetails
            eConsultDetails={eConsult.full_details}
          />
        </div>
      </Col>
      <Col className="desktop-section fixed-550">
        <EConsultEvents eConsult={eConsult} desktopVersion />
        {eConsult.needs_disposition ?
          <ResponseBody eConsult={eConsult} desktopVersion /> : null
        }
      </Col>
    </Row>
  </Container>
);

DesktopExperience.propTypes = {
  eConsult: PropTypes.shape({
    appropriateness: PropTypes.string,
    attachments: PropTypes.array,
    disclaimer: PropTypes.bool,
    full_details: PropTypes.shape({
      date_of_birth: PropTypes.string.isRequired,
      patient_id: PropTypes.number.isRequired,
      mrn: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      clinic: PropTypes.string.isRequired,
      masked_id: PropTypes.string.isRequired,
      notes: PropTypes.string,
      assignment_date: PropTypes.string.isRequired,
      disposition_due_date: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    issue_summary: PropTypes.string.isRequired,
    main_question: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    recommendation: PropTypes.string,
    recommended_specialty: PropTypes.string,
    specialist_form_version: PropTypes.string,
    status: PropTypes.string.isRequired,
    needs_disposition: PropTypes.bool.isRequired,
    reroute_history: PropTypes.arrayOf(PropTypes.shape({
      rerouted_at: PropTypes.shape({
        date: PropTypes.string,
      }),
      specialty: PropTypes.string,
    })),
  }),
};
DesktopExperience.defaultProps = {
  eConsult: {},
};

export default DesktopExperience;
