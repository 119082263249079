import { all, takeEvery } from 'redux-saga/effects';
import verifyAccess from './verify-access';
import browser from './browser';
import econsultsSaga from './econsults/econsultsSaga';
import fetchUser from './fetch-user';
import practitionerSaga from './practitioner/practitionerSaga';
import starRatingQuestionSetSaga from './starRatingQuestionSet/starRatingQuestionSetSaga';
import fetchAssetPreviewStatus from './asset/fetchAssetPreviewStatus';

import {
  VERIFY_ACCESS,
  FETCH_USER,
  FETCH_ASSET_PREVIEW_STATUS,
} from '../state';


export default function* rootSaga() {
  yield all([
    yield takeEvery(['CALCULATE_RESPONSIVE_STATE', 'redux-responsive/CALCULATE_RESPONSIVE_STATE'], browser),
    yield* econsultsSaga(),
    yield* starRatingQuestionSetSaga(),
    yield takeEvery(VERIFY_ACCESS, verifyAccess),
    yield takeEvery(FETCH_USER, fetchUser),
    yield takeEvery(FETCH_ASSET_PREVIEW_STATUS, fetchAssetPreviewStatus),
    yield* practitionerSaga(),
  ]);
}
