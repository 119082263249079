import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import SideDrawerBtn from '../side-drawer-btn/SideDrawerBtn';
import HeaderText from '../header-text/HeaderText';
import { insidePage } from '../../utils';


const PageContent = ({ children }) => {
  /**
   * Verifies if current page is econsult details
   */
  const insideEConsultsPage = insidePage('econsults');

  /**
   * Browser info react hook
   */
  const browser = useSelector(state => state.browser);

  /**
   * How the page is configured
   * @type {{eConsultsOnMobile: *, mainOnDesktop: (boolean|boolean)}}
   */
  const pageDefinitions = {
    eConsultsOnMobile: browser.lessThan.medium && insideEConsultsPage,
    mainOnDesktop: !insideEConsultsPage && browser.greaterThan.medium,
  };

  /**
   * Defines the classes for header section
   * @type {string}
   */
  const headerClass = `${pageDefinitions.eConsultsOnMobile ? 'mx-auto' : 'mr-auto'}` +
    `${pageDefinitions.mainOnDesktop ? ' pl-4' : ''}`;

  /**
   * Defines the classes for side drawer button
   * @type {string}
   */
  const sideDrawerClass = pageDefinitions.mainOnDesktop ? 'd-none' : '';

  /**
   * Defines the classes for the page body section
   * @type {string}
   */
  const pageBodyClass = `page-body${insideEConsultsPage ? ' overflow-hidden' : ''}`;

  /**
   * Defines the classes for the page content section
   * @type {string}
   */
  const pageContentClass = `page-content${insideEConsultsPage ? ' pl-0' : ''}`;

  return (
    <div className={pageContentClass}>
      <Breadcrumbs />
      <div className="menu-title">
        <div className="align-items-center d-flex p-3">
          <div className={sideDrawerClass}><SideDrawerBtn /></div>
          <div className={headerClass}><HeaderText /></div>
        </div>
      </div>
      <div className={pageBodyClass}>
        {children}
      </div>
    </div>
  );
};

const {
  array,
  element,
  oneOfType,
} = PropTypes;

PageContent.propTypes = {
  children: oneOfType([
    element,
    array,
  ]).isRequired,
};

export default PageContent;
