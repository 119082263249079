import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { errorColor } from '../../../../utils/formatters/cssClass';
import BigRadioGroup from '../../../common/big-radio-group/BigRadioGroup';
import SpecialtySelector from '../../../common/specialty-selector/SpecialtySelector';
import ErrorText from '../../../common/error-text/ErrorText';
import Specialties from '../../../../api-calls/Specialties';
import VisitUrgencySelector from '../../visit-urgency-selector/VisitUrgencySelector';
import { selectUser } from '../../../../state';


const ResponseApropriateness = ({ eConsult, isWaitingForSpecialistComment, desktopVersion }) => {
  const {
    errors,
    register,
    setValue,
  } = useFormContext();
  /**
   * Defines when the specialty selector must be disabled
   */
  const [disableSpecialtySelection, setDisableSpecialtySelection] =
    useState(eConsult.appropriateness !== 'wrong_specialty' ||
      isWaitingForSpecialistComment());

  /**
   * Defines when the visit  selector must be disabled
   */
  const [disableVisitSelection, setDisableVisitSelection] =
  useState(eConsult.appropriateness !== 'face_to_face' ||
    isWaitingForSpecialistComment());

  /**
   * Defines what is the current selected specialty
   */
  const [recommendedSpecialty, setRecommendedSpecialty] =
    useState(eConsult.recommended_specialty);

  /**
   * Defines what is the current selected option in visit selector
   */
  const [visitUrgency, setVisitUrgency] =
  useState(eConsult.visit_urgency);

  /**
   * Defines the API callback function in order to retrieve the recommended specialties
   * @returns {Promise} api call to execute
   */
  const recommendedSpecialtiesApiCallback = () => Specialties().getRecommendedSpecialties(
    eConsult.organization_id,
    Math.floor(eConsult.patient_age_in_days / 365), // Transforms days into years
    eConsult.specialty_name,
  );

  /**
   * Disables the specialty selector in case the selected disposition is not wrong specialty
   * @param e what is the selected disposition input
   * @return {void}
   */
  const disableSpecialtySelector = (e) => {
    setDisableSpecialtySelection(e.target.value !== 'wrong_specialty');
    if (e.target.value !== 'wrong_specialty') {
      // Resets the value in the form hook
      setValue('recommended_specialty', null);
      // Resets the value in the input
      setRecommendedSpecialty(null);
    }
  };

  /**
   * Disables the visit selector in case the selected disposition is not face to face
   * @param e what is the selected disposition input
   * @return {void}
   */
  const disableVisitSelector = (e) => {
    setDisableVisitSelection(e.target.value !== 'face_to_face');
    if (e.target.value !== 'face_to_face') {
      // Resets the value in the form hook
      setValue('visit_urgency', null);
      // Resets the value in the input
      setVisitUrgency(null);
    }
  };

  /**
   * Disables both selectors visit and specialty of different appropriateness is selected
   *  (not those that have selectors)
   * @param e what is the selected disposition input
   * @return {void}
   */
  const disableAppropiatenessSelectors = (e) => {
    disableSpecialtySelector(e);
    disableVisitSelector(e);
  };

  const currentUserState = useSelector(state => selectUser(state));

  useEffect(() => {
    // Custom field registration
    if (!currentUserState.specialist_visit_urgency) {
      register({ name: 'visit_urgency' });
      setValue('visit_urgency', '');
    }
  }, []);

  return (
    <FormGroup>
      <FormGroup tag="fieldset" row>
        <legend className="col-form-label col">
          {React.createElement((desktopVersion ? 'h5' : 'p'), {
            className: `font-weight-lighter ${errorColor(errors, 'appropriateness')}`,
          }, 'Select a care plan summary')}
        </legend>
        <BigRadioGroup
          className={`appropriateness-options ${errorColor(errors, 'appropriateness')}`}
          name="appropriateness"
          options={([
            {
              value: 'ok_to_treat',
              text: 'Treatment options available at the PCP level',
            },
            {
              value: 'face_to_face',
              text: 'In-person visit with specialist recommended.',
              underContent: currentUserState.specialist_visit_urgency ? <VisitUrgencySelector
                innerRef={register({ required: !isWaitingForSpecialistComment() })}
                className="visit-urgency-selector"
                name="visit_urgency"
                disabled={disableVisitSelection}
                invalid={!_.isUndefined(errors.visit_urgency)}
                value={visitUrgency}
                clearAfterDisable={!isWaitingForSpecialistComment()}
                dataAut="visit-urgency-selector"
                onChange={disableVisitSelector}
              /> : null,
            },
            {
              value: 'pending_requester_feedback',
              text: 'I need more info to provide a care plan.',
            },
            {
              value: 'wrong_specialty',
              text: `This is not a ${eConsult.specialty_name} case. Reroute request to:`,
              underContent: <SpecialtySelector
                name="recommended_specialty"
                apiCallback={recommendedSpecialtiesApiCallback}
                className="recommended-speciality-selector"
                disabled={disableSpecialtySelection}
                invalid={!_.isUndefined(errors.recommended_specialty) && !disableSpecialtySelection}
                value={recommendedSpecialty}
                clearAfterDisable={!isWaitingForSpecialistComment()}
                dataAut="recommended-specialty-selector"
              />,
            },
          ])}
          innerRef={register({ required: !isWaitingForSpecialistComment() })}
          invalid={!_.isUndefined(errors.appropriateness)}
          disabled={isWaitingForSpecialistComment()}
          onChange={disableAppropiatenessSelectors}
        />
        <ErrorText
          visible={!_.isUndefined(errors.recommended_specialty) && !disableSpecialtySelection}
          className="pt-2 recommended-speciality-error-message"
        >
          Required
        </ErrorText>
        <ErrorText visible={!_.isUndefined(errors.appropriateness)} className="pt-2 pl-4">
          Required
        </ErrorText>
      </FormGroup>
    </FormGroup>
  );
};

ResponseApropriateness.propTypes = {
  eConsult: PropTypes.shape({
    organization_id: PropTypes.number,
    patient_age_in_days: PropTypes.number,
    specialty_name: PropTypes.string,
    recommended_specialty: PropTypes.string,
  }).isRequired,
  isWaitingForSpecialistComment: PropTypes.func.isRequired,
  desktopVersion: PropTypes.bool.isRequired,
};

export default ResponseApropriateness;
