import UppyCore from '@uppy/core';

// *************** PLUGINS ***************
const Webcam = require('@uppy/webcam');
const XHRUpload = require('@uppy/xhr-upload');

export default class Uppy {
  static instance;

  /**
   * Initializes Uppy instance
   */
  static init() {
      this.instance = new UppyCore({
      autoProceed: false, // Requires confirmation by user before upload the files
      meta: { // Any extra field required by the endpoint
        security_profile: 'sensitive',
      },
      restrictions: {
        maxFileSize: 100 * 1024 * 1024, // 100MB
        allowedFileTypes: [ // Allowed extensions
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.dcm',
          '.tif',
          '.tiff',
          '.bmp',
          '.mp4',
          '.mov',
          '.pdf',
          '.plain',
          '.rtf',
          '.text',
          '.txt',
          '.csv',
        ],
      },
    });
    this.setPlugins([
      { // Webcam plugin
        class: Webcam,
        options: {
          modes: ['picture'], // Only allows to take pictures, No videos
        },
      },
      { // BE Upload plugin
        class: XHRUpload,
        options: {
          endpoint: '/api/mobile/specialist/assets',
          fieldName: 'content', // What is the name of the field for file
          metaFields: ['security_profile'], // What meta info will be sent to BE
          // Default response transformation
          getResponseData: responseText => ({ id: responseText }),
        },
      },
    ]);
  }

  /**
   * Used by the initiator method in order to set all the required plugins
   * @param plugins
   */
  static setPlugins(plugins) {
    plugins.forEach(plugin => this.instance.use(plugin.class, plugin.options || {}));
  }

  /**
   * Sets a Uppy event
   * @param event event name
   * @param callback function
   */
  static setEvent(event, callback) {
    this.instance.off(event);
    this.instance.on(event, callback);
  }

  /**
   * Allows to modify one or many options for an specific plugin
   * @param id Plugin id (Name by default)
   * @param options new option or options to apply
   */
  static modifyPluginOption(id, options) {
    this.instance.getPlugin(id).setOptions(options);
  }

  /**
   * Allows to set the bearer token for the XHRUpload plugin (Authentication)
   * @param bearerToken access token
   */
  static setBearerToken(bearerToken) {
    this.modifyPluginOption('XHRUpload', {
      headers: {
        authorization: `Bearer ${bearerToken}`,
      },
    });
  }

  /**
   * Allows to add a new file into the uppy instance
   * @param attributes what are the file attributes
   * @param state what is the file state
   */
  static addFile(attributes, state) {
    const fileId = this.instance.addFile(attributes);
    this.instance.setFileState(fileId, state);
  }

  /**
   * Returns the Uppy instance in order to be sent into React components
   * @return {*}
   */
  static getInstance() {
    return this.instance;
  }

  /**
   * Resets the file state in the Uppy instance
   */
  static reset() {
    this.instance.reset();
  }
}
