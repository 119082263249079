import APICallBase from './APICallBase';

class EConsults extends APICallBase {
  constructor() {
    super();
    this.url = '/econsults';
  }

  /**
   * Sends the EConsult heartbeat to update cpt time
   * @param eConsultId eConsult id
   * @returns {Promise}
   */
  heartbeat(eConsultId) {
    this.url = `${this.url}/${eConsultId}/heartbeat`;
    return this.post({});
  }
  /**
   * Get the list of comments associated to a given econsult
   * sorted from oldest to newest
   * @param econsultId
   * @return {Promise} endpoint call promise
   */
  getComments(econsultId) {
    return this.get(null, `${econsultId}/comments`).call();
  }
  /**
   * Get the list of comments associated to a given econsult
   * sorted from oldest to newest
   * @param econsultId
   * @return {Promise} endpoint call promise
   */
  getPatientHistory(econsultId) {
    return this.get(null, `${econsultId}/patientHistory`).call();
  }
  /**
   * Post a comment for an specific econsult
   * @param econsultId
   * @param body
   * @return {Promise} endpoint call promise
   */
  postComment(econsultId, body) {
    this.url = `${this.url}/${econsultId}/comments`;
    return this.post(body);
  }

  /**
   * Post a comment for an specific econsult
   * @param econsultId
   * @param body
   * @return {Promise} endpoint call promise
   */
  patchReview(econsultId, body) {
    return this.patch(`${econsultId}/review`, body);
  }
}

export default () => new EConsults();

