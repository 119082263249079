import createReducer from './createReducer';
/** ********************************************************
 * CONSTANTS
 ******************************************************** */
export const PRACTITIONER = 'practitioner/PRACTITIONER';
export const FETCH_PRACTITIONER = 'practitioner/FETCH_PRACTITIONER';
export const FETCH_PRACTITIONER_SCHEDULE = 'practitioner/FETCH_PRACTITIONER_SCHEDULE';
export const PRACTITIONER_SCHEDULE_DATES = 'practitioner/PRACTITIONER_SCHEDULE_DATES';
export const IS_FETCHING_PRACTITIONER_SCHEDULE = 'practitioner/IS_FETCHING_PRACTITIONER_SCHEDULE';
export const PRACTITIONER_SCHEDULE_ERROR = 'practitioner/PRACTITIONER_SCHEDULE_ERROR';
export const PRACTITIONER_SCHEDULE_RESET = 'practitioner/PRACTITIONER_SCHEDULE_RESET';
export const SAVE_PRACTITIONER_SCHEDULE = 'practitioner/SAVE_PRACTITIONER_SCHEDULE';
export const IS_SAVING_SCHEDULE = 'practitioner/IS_SAVING_SCHEDULE';
export const FHIR_PRACTITIONER_SCHEDULE = 'practitioner/FHIR_PRACTITIONER_SCHEDULE';
export const FHIR_PRACTITIONER_SCHEDULE_DATES = 'practitioner/FHIR_PRACTITIONER_SCHEDULE_DATES';
/** ********************************************************
 * REDUCER
 ******************************************************** */
export const initialStatePractitioner = {
  practitioner: null,
  scheduleDates: [],
  isFetchingSchedule: true,
  scheduleError: false,
  isSavingSchedule: false,
  fhirPractitionerSchedule: null,
  fhirPractitionerScheduleDates: null,
};

export default createReducer(initialStatePractitioner, {
  [PRACTITIONER]: (state, { payload }) => ({
    ...state, practitioner: payload,
  }),
  [PRACTITIONER_SCHEDULE_DATES]: (state, { payload }) => ({
    ...state, scheduleDates: payload,
  }),
  [IS_FETCHING_PRACTITIONER_SCHEDULE]: (state, { payload }) => ({
    ...state, isFetchingSchedule: payload,
  }),
  [PRACTITIONER_SCHEDULE_ERROR]: (state, { payload }) => ({
    ...state, scheduleError: payload,
  }),
  [PRACTITIONER_SCHEDULE_RESET]: state => ({
    ...initialStatePractitioner,
    practitioner: state.practitioner,
    fhirPractitionerSchedule: state.fhirPractitionerSchedule,
  }),
  [IS_SAVING_SCHEDULE]: (state, { payload = [] }) => ({
    ...state, isSavingSchedule: payload,
  }),
  [FHIR_PRACTITIONER_SCHEDULE]: (state, { payload }) => ({
    ...state, fhirPractitionerSchedule: payload,
  }),
  [FHIR_PRACTITIONER_SCHEDULE_DATES]: (state, { payload }) => ({
    ...state, fhirPractitionerScheduleDates: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

export const setPractitioner = practitioner => ({
  type: PRACTITIONER,
  payload: practitioner,
});

export const fetchPractitioner = () => ({
  type: FETCH_PRACTITIONER,
});

export const fetchPractitionerSchedule = params => ({
  type: FETCH_PRACTITIONER_SCHEDULE,
  ...params,
});

export const setPractitionerScheduleData = dates => ({
  type: PRACTITIONER_SCHEDULE_DATES,
  payload: dates,
});

export const setIsFetchingPractitionerSchedule = bool => ({
  type: IS_FETCHING_PRACTITIONER_SCHEDULE,
  payload: bool,
});

export const setPractitionerScheduleError = bool => ({
  type: PRACTITIONER_SCHEDULE_ERROR,
  payload: bool,
});

export const resetPractitionerSchedule = () => ({ type: PRACTITIONER_SCHEDULE_RESET });

export const savePractitionerSchedule = (
  resolve,
  currentUnavailableDates,
  originalUnavailableDates,
  startDate,
  endDate,
) => ({
  type: SAVE_PRACTITIONER_SCHEDULE,
  resolve,
  currentUnavailableDates,
  originalUnavailableDates,
  startDate,
  endDate,
});

export const setIsSavingSchedule = bool => ({
  type: IS_SAVING_SCHEDULE,
  payload: bool,
});

export const setFhirPractitionerSchedule = fhirPractitionerSchedule => ({
  type: FHIR_PRACTITIONER_SCHEDULE,
  payload: fhirPractitionerSchedule,
});

export const setFhirPractitionerScheduleDates = fhirPractitionerScheduleDates => ({
  type: FHIR_PRACTITIONER_SCHEDULE_DATES,
  payload: fhirPractitionerScheduleDates,
});

/** ********************************************************
 * SELECTORS
 ******************************************************** */

export const selectPractitioner = state => state.practitioner.practitioner;

export const selectPractitionerScheduleData = state => state.practitioner.scheduleDates;

export const selectIsFetchingPractitionerSchedule = state => state.practitioner.isFetchingSchedule;

export const selectPractitionerScheduleError = state => state.practitioner.scheduleError;

export const selectIsSavingSchedule = state => state.practitioner.isSavingSchedule;

export const selectFhirPractitionerSchedule = state => state.practitioner.fhirPractitionerSchedule;

export const selectFhirPractitionerScheduleDates = state =>
  state.practitioner.fhirPractitionerScheduleDates;
