import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal, ModalBody, Row } from 'reactstrap';
import {
  assetPreviewUrl,
  assetThumbnailUrl,
  verifyFormat,
  formattedName,
  previousEConsultUrl,
  eConsultThumbnailUrl,
} from '../../../utils/formatters/attachments';
import EconsultTitle from '../econsult-title/EconsultTitle';
import CustomModalHeader from '../../modal-header/ModalHeader';
import FileViewer from '../../file-viewer/FileViewer';

const ModalAttachment = ({
  attachment,
  eConsult,
  isPreviousEConsult,
  desktopVersion,
}) => {
  const [modal, setModal] = useState(false);
  const fileExtension = _.last(_.split(_.toLower(attachment.name), '.'));
  const displayAttachmentPageNumber = attachment.pages && desktopVersion;
  const toggle = () => {
    setModal(!modal && (verifyFormat(attachment) || isPreviousEConsult));
  };
  return (
    <Row onClick={toggle} className="attachment-row align-items-center" data-auto={`attachment-${attachment.id}`}>
      <div className="px-3">
        <img
          src={isPreviousEConsult ?
            eConsultThumbnailUrl(attachment.id) : assetThumbnailUrl(attachment.id)
          }
          data-auto="attachment-id"
          height={64}
          alt={attachment.name}
          className="border"
        />
      </div>
      <div className={`attachment-full-name pr-3${displayAttachmentPageNumber ? ' with-page' : ''}`}>
        <div className="attachment-name-container d-flex">
          {isPreviousEConsult && desktopVersion ? (
            <p className="attachments-modal-btn text-truncate m-0" data-auto="attachment-name">
              {_.upperFirst(attachment.name)}
            </p>
          ) : (
            <React.Fragment>
              <p className="attachments-modal-btn text-truncate m-0 text-underline" data-auto="attachment-name">
                {formattedName(attachment.name)}
              </p>
              <p className="m-0 text-underline" data-auto="attachment-extension">.{fileExtension}</p>
            </React.Fragment>
          )}
        </div>
        <p className="format-error-attachment m-0" data-auto="attachment-mobile-error">
          {verifyFormat(attachment) || isPreviousEConsult ? '' :
          <i className="fa fa-exclamation-triangle">
            <span className="warning-text">Cannot open on mobile</span>
          </i>
          }
        </p>
      </div>
      {displayAttachmentPageNumber ? (
        <div className="ml-auto px-3">
          <p className={`m-0${isPreviousEConsult ? ' text-underline' : ''}`}>{ isPreviousEConsult ? attachment.pages : `${attachment.pages} pages`}</p>
        </div>
      ) : null}
      <Modal
        id="econsult-attachment-modal"
        isOpen={modal}
        toggle={toggle}
        className="mw-100 h-100 m-0 full-screen-modal"
        wrapClassName={desktopVersion ? 'modal-desktop-attachments' : ''}
      >
        <CustomModalHeader {...{ toggle, desktopVersion }}>
          <EconsultTitle {...{ eConsult, desktopVersion, attachmentName: attachment.name }} />
        </CustomModalHeader>
        <ModalBody className={`mw-100 h-100 ${attachment?.mime_type?.includes('video') ? 'p-2' : ''}`}>
          <FileViewer file={attachment} urlPreview={isPreviousEConsult ?
            previousEConsultUrl(attachment.id) : assetPreviewUrl(attachment.id)} />
        </ModalBody>
      </Modal>
    </Row>
  );
};
ModalAttachment.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    name: PropTypes.string.isRequired,
    pages: PropTypes.string,
  }).isRequired,
  eConsult: PropTypes.shape({
    patient_name: PropTypes.string.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
  }),
  isPreviousEConsult: PropTypes.bool,
  desktopVersion: PropTypes.bool.isRequired,
};

ModalAttachment.defaultProps = {
  eConsult: {},
  isPreviousEConsult: false,
};


export default ModalAttachment;
