import Base from './Base';
import store from '../../store';
import { setFhirPractitionerSchedule } from '../../state';

class Practitioner extends Base {
  className = 'Practitioner';
  /**
   * Required attributes from resource
   * @type {[string]}
   */
  requiredAttributes = ['id']

  /**
   * Parses panel id
   * @return {number|null}
   */
  parsePanelId() {
    if (this.resource.contained) {
      const containedPanel = this.resource.contained.find(c => c.title === 'panels');
      if (containedPanel) {
        const panelReference = containedPanel.entry[0]?.item?.reference;
        if (panelReference) {
          return parseInt(panelReference.split('/')[1], 10);
        }
      }
    }
    return null;
  }

  /**
   * Parses schedule id
   * @return {*}
   */
  parseSchedule() {
    if (this.resource.contained) {
      const containedSchedule = this.resource.contained.find(c => c.resourceType === 'Schedule');
      store.dispatch(setFhirPractitionerSchedule(containedSchedule));
      if (containedSchedule) {
        const scheduleData = {};
        scheduleData.id = containedSchedule.id;
        scheduleData.lastAvailabilitySubmission = this.getExtensionValue([
          '/fhir/structureDefinition/scheduleUpdatesInformation',
          'last_availability_submission',
        ], containedSchedule.extension);
        scheduleData.availabilityValidThrough = this.getExtensionValue([
          '/fhir/structureDefinition/scheduleUpdatesInformation',
          'availability_valid_through',
        ], containedSchedule.extension);
        return scheduleData;
      }
    }
    return null;
  }

  /**
   * Parses user id
   * @return number
   */
  parseUserId() {
    if (this.resource.identifier) {
      return this.resource.identifier
        .find(identifier => identifier.system === 'sp_user_id').value;
    }

    return null;
  }
}

export default fhirResource => new Practitioner(fhirResource);
