import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form as ReactstrapForm, Input, Row, Col, Container, Button,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import diff from 'd2l-json-patch-gen';
import { isEmpty } from 'lodash';
import StarRating from '../../../star-rating/StarRating';
import EConsults from '../../../../api-calls/EConsults';
import Loading from '../../../common/loading/Loading';
import { selectStarRatingQuestionSet, selectStarRatingQuestionSetError } from '../../../../state';

const Form = ({ eConsult, onSubmitted }) => {
  /**
   * What Question id must be used for specialist rating options
   * @type {number}
   */
  const SPECIALIST_QUESTION_SET_ID = 4;

  // Timeout to be cleared once the component is destroyed
  let loopTimeout = null;

  // Original values to be patched
  const originalForm = useRef({
    answer: null,
    question_set_id: SPECIALIST_QUESTION_SET_ID,
    review_selected_options: null,
    comment: '',
  });

  const defaultValues = { ...originalForm.current };

  const methods = useForm({ defaultValues });

  const dispatch = useDispatch();
  const questionSet = useSelector((state) => selectStarRatingQuestionSet(state));
  const questionSetError = useSelector((state) => selectStarRatingQuestionSetError(state));
  const [isSavingChanges, setIsSavingChanges] = useState(false);

  /**
   * Finds the JSON Patch difference between original values and the new changes
   * @return {*|Array} JSON Patch operations
   */
  const calculateDifference = () => diff({ ...originalForm.current }, { ...methods.getValues() });

  /**
   * Finds any difference and sends the expected operations to the BE in order to update the review
   */
  const saveChanges = () => {
    if (!isSavingChanges) {
      const difference = calculateDifference();
      if (!isEmpty(difference)) {
        setIsSavingChanges(true);
        EConsults().patchReview(eConsult.id, difference).then((response) => {
          originalForm.current = response.data;
        }).finally(() => setIsSavingChanges(false));
      }
    }
  };

  /**
   * Triggered once user submits the form
   */
  const onSubmit = () => {
    saveChanges();
    onSubmitted();
  };

  /**
   * Sets a time out of 3 seconds in order to check differences and upload them to the server
   * @returns {number} time out id
   */
  const setSubmitTimeout = () => setTimeout(() => {
    saveChanges();
    loopTimeout = setSubmitTimeout();
  }, 3000);

  /**
   * Dispatch the action to load star rating question set
   */
  const loadStarRatingQuestionSet = () => {
    dispatch({
      type: 'starRatingQuestionSetSaga/FETCH_STAR_RATING_QUESTION_SET',
      questionSetId: SPECIALIST_QUESTION_SET_ID,
    });
  };

  useEffect(() => {
    loopTimeout = setSubmitTimeout();
    // Custom field registration
    methods.register({ name: 'question_set_id' });
    methods.setValue('question_set_id', SPECIALIST_QUESTION_SET_ID);
    return () => {
      clearTimeout(loopTimeout);
    };
  }, []);

  return (
    <Loading
      size="md"
      loadFunc={loadStarRatingQuestionSet}
      isReloadable
      isLoaded={questionSet?.id === SPECIALIST_QUESTION_SET_ID}
      isError={!!questionSetError}
    >
      <FormProvider {...methods} >
        <ReactstrapForm onSubmit={methods.handleSubmit(onSubmit)}>
          <Container>
            <StarRating />
            <Row>
              <Col xs={12}>
                <Input
                  type="textarea"
                  name="comment"
                  data-auto="submit-feedback-specialist-review-comment"
                  placeholder="Add comments (optional)"
                  rows={6}
                  innerRef={methods.register({ required: false })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="font-size-smaller text-justify mt-2 text-primary">
                Your feedback is anonymously shared with providers to
                help them improve the clinical quality of their requests.
              </Col>
            </Row>
            <div className="mt-3 submit-button-container">
              <Button
                color="primary"
                size="lg"
                block
                data-auto="submit-feedback-submit-button"
              >
                <span> Submit </span>
              </Button>
            </div>
          </Container>
        </ReactstrapForm>
      </FormProvider>
    </Loading>
  );
};

Form.propTypes = {
  eConsult: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onSubmitted: PropTypes.func.isRequired,
};

export default Form;
