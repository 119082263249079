import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

const BigCheckbox = ({
  children,
  name,
  onChange,
  innerRef,
  invalid,
  className,
  disabled,
}) => (
  <FormGroup check className={className}>
    <Label check className="big-checkbox-container">
      <div className="big-checkbox-text">
        {children}
      </div>
      <Input
        type="checkbox"
        data-auto={`specialist-mobile-${name}`}
        name={name}
        onChange={onChange}
        innerRef={innerRef}
        invalid={invalid}
        className="big-checkbox-input"
        disabled={disabled}
      />
      <span className="big-checkbox-checkmark" />
    </Label>
  </FormGroup>
);

BigCheckbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  innerRef: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

BigCheckbox.defaultProps = {
  onChange: () => {},
  className: '',
  disabled: false,
};

export default BigCheckbox;
