import React, {useEffect,useState} from 'react';
import { FormGroup, Label } from 'reactstrap';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { errorColor } from '../../../../utils/formatters/cssClass';
import BigCheckbox from '../../../common/big-checkbox/BigCheckbox';
import ErrorText from '../../../common/error-text/ErrorText';

const ResponseDisclaimer = ({ desktopVersion, disclaimerText }) => {
  const { errors, setValue, register } = useFormContext();

  return (
    <FormGroup>
      <Label>
        {React.createElement(desktopVersion ? 'h5' : 'p', {
          className: `font-weight-lighter ${errorColor(errors, 'disclaimer')}`,
        }, 'Review disclaimer')}
      </Label>
      <BigCheckbox
        name="disclaimer"
        data-auto="specialist-mobile-disclaimer"
        innerRef={register({ required: true })}
        invalid={!_.isUndefined(errors.disclaimer)}
        className={errorColor(errors, 'disclaimer')}
      >
       {disclaimerText}
      </BigCheckbox>
      <ErrorText
        visible={!_.isUndefined(errors.disclaimer)}
        className="pt-2 pl-1"
      >
        Required
      </ErrorText>
    </FormGroup>
  );
};

ResponseDisclaimer.propTypes = {
  desktopVersion: PropTypes.bool,
};

ResponseDisclaimer.defaultProps = {
  desktopVersion: false,
};

export default ResponseDisclaimer;
