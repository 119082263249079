import APICallBase from './APICallBase';

class Users extends APICallBase {
  constructor() {
    super();
    this.url = '/users';
  }
}

export default () => new Users();

