import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Drawer from 'rc-drawer';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Session from '../../services/Session';
import { insidePage } from '../../utils';
import {
  selectBrowserViewport,
  selectSideDrawerTop,
  selectIsSideDrawerOpen,
  selectSideDrawerHeight,
  setIsSideDrawerOpen,
  selectUser,
} from '../../state';
import EnvironmentSettings from '../../services/EnvironmentSettings';

const clinicalAppUrl = `https://app${EnvironmentSettings.domainServer}#/app/submit-availability`;

const SideDrawer = (props) => {
  if (props.browserViewport.greaterThan.medium && insidePage('econsults')) {
    props.setDrawerOpen(false);
  }
  return (
    <Drawer
      open={props.isSideDrawerOpen}
      showMask={props.browserViewport.lessThan.medium}
      handler={false}
      onClose={() => {
          props.setDrawerOpen(false);
        }}
      level={null}
      style={{
          height: props.sideDrawerHeight,
        }}
    >
      <div className="link-container">
        <div className="profile-info-container pl-3">
          <h4>AristaMD</h4>
          <p data-auto="side-menu-user-full-name">{props.user.full_name}</p>
        </div>
        <div className="link-section">
          <NavLink
            className="link"
            exact
            to={'/'}
            data-auto="my-requests-link"
          >
              My Request
          </NavLink>
          <NavLink
            className="link"
            exact
            to={`/history`}
            data-auto="request-history-link"
          >
              Request History
          </NavLink>
          { /* <NavLink
            className="link"
            to="#"
            data-auto="scheduling-link"
            onClick={() => {
              goTo('/scheduling');
            }
          }
          >
            My Schedule
          </NavLink>
          <NavLink
            className="link"
            to="#"
            data-auto="availability-link"
            onClick={() => {
              goTo('/availability');
            }
          }
          >
            Submit Availability
          </NavLink> */ }
          <a
            className={props.browserViewport.greaterThan.medium ? 'link' : 'd-none'}
            target="_blank"
            rel="noopener noreferrer"
            href={clinicalAppUrl}
            data-auto="support-link"
          >
          Scheduling
          </a>
          <a
            className="link"
            to="#"
            href="mailto:support@aristamd.com"
            data-auto="support-link"
          >
            Support
          </a>
          <NavLink
            className="link"
            to="#"
            onClick={() => {
              props.setDrawerOpen(false);
              Session.logout();
            }}
            data-auto="logout-link"
          >
              Logout
          </NavLink>
        </div>
      </div>
    </Drawer>
  );
};

SideDrawer.propTypes = {
  browserViewport: PropTypes.shape({
    greaterThan: PropTypes.shape({
      small: PropTypes.bool,
      medium: PropTypes.bool,
    }),
    lessThan: PropTypes.shape({
      medium: PropTypes.bool,
    }),
  }).isRequired,
  isSideDrawerOpen: PropTypes.bool.isRequired,
  sideDrawerHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
  }).isRequired,
};


function mapStateToProps(state) {
  return {
    browserViewport: selectBrowserViewport(state),
    isSideDrawerOpen: selectIsSideDrawerOpen(state),
    sideDrawerTop: selectSideDrawerTop(state),
    sideDrawerHeight: selectSideDrawerHeight(state),
    user: selectUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setDrawerOpen: setIsSideDrawerOpen,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
