import { Col, Container, Row } from 'reactstrap';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const UpperCollapsibleSection = ({
  children,
  dataAut,
  getTitle,
  onHide,
  onShow,
  showDetails,
}) => {
  const [isOpen, setIsOpen] = useState(showDetails);
  const toogleContent = () => {
    // eslint-disable-next-line no-unused-expressions
    isOpen ? onShow() : onHide();
    setIsOpen(!isOpen);
  };
  const getItemTitle = getTitle(isOpen);
  return (
    <Container className="p-0">
      <Row className="p-1">
        <Col>
          {isOpen ? children : null}
          <h5
            className="text-primary font-weight-normal"
            onClick={toogleContent}
            role="presentation"
            data-auto={dataAut}
          >
            {getItemTitle}
          </h5>
        </Col>
      </Row>
    </Container>
  );
};

UpperCollapsibleSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  getTitle: PropTypes.func,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  dataAut: PropTypes.string,
  showDetails: PropTypes.bool,
};

UpperCollapsibleSection.defaultProps = {
  children: (
    <div>
      <h3 data-auto="place-holder-uppercollapsible" className="text-center bg-dark text-white">Something will be added here</h3>
    </div>
  ),
  getTitle: showDetails => (`${!showDetails ? 'Show' : 'Hide'} Something`),
  onHide: () => {},
  onShow: () => {},
  dataAut: 'collapsible-link',
  showDetails: false,
};

export default UpperCollapsibleSection;

