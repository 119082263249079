import createReducer from './createReducer';

/* constants */
export const HEADER_TITLE = 'headerText/HEADER_TITLE';


/** ********************************************
 * reducer
 ******************************************** */
export const initialHeaderState = {
  headerTitle: null,
};
export default createReducer(initialHeaderState, {
  [HEADER_TITLE]: (state, { payload }) => ({ headerTitle: payload }),
});

/** ********************************************
 * actions
 ******************************************** */

/**
  * @param {string} title
  * @return {{type: string, payload: *}}
  */

export const setHeaderTitle = title => ({
  type: HEADER_TITLE,
  payload: title,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {string}
 */
export const selectHeaderTitle = state => state.headerText.headerTitle;

