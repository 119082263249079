import Base from './Base';

class Group extends Base {
  className = 'Group';
  parseScheduleStartDate() {
    return this.getExtensionValue([
      '/fhir/structureDefinition/panelInformation',
      'lastScheduleGeneratedStartDate',
    ]);
  }
  parseScheduleEndDate() {
    return this.getExtensionValue([
      '/fhir/structureDefinition/panelInformation',
      'lastScheduleGeneratedEndDate',
    ]);
  }
  parseLatestScheduledDate() {
    return this.getExtensionValue([
      '/fhir/structureDefinition/panelInformation',
      'latestScheduledDate',
    ]);
  }
}

export default fhirResource => new Group(fhirResource);
