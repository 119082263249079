import React, { useState, useEffect, useRef, forwardRef } from 'react';
import ReactRefreshInfiniteTableView from 'react-refresh-infinite-tableview';
import { ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';

// /**
//  * This is a customization of the original Pullable component with the following improvements:
//  * 1. Allows to trigger the pull to refresh in desktop view.
//  * 2. Stops the spinner once a completed callback has been triggered.
//  * 3. Sends the event in the shouldPullToRefresh function in order to recognize the element
//  * that triggered the event
//  */
// class CustomPullable extends Pullable {
//   componentDidMount() {
//     // It's required to attach the event to the child element since it was being added to the entire
//     // window
//     // eslint-disable-next-line react/no-find-dom-node
//     const currentNode = ReactDom.findDOMNode(this);
//     const childNode = currentNode.parentElement.getElementsByClassName('infinite-queue')[0];
//     childNode.addEventListener('touchstart', this.onTouchStart);
//     childNode.addEventListener('touchmove', this.onTouchMove, { passive: false });
//     childNode.addEventListener('touchend', this.onTouchEnd);
//     childNode.addEventListener('mousedown', this.onTouchStart);
//     childNode.addEventListener('mousemove', this.onTouchMove);
//     childNode.addEventListener('mouseup', this.onTouchEnd);
//   }
//   componentWillUnmount() {
//     // It's required to remove the event to the child element since it was being removed to the
//     // entire window
//     // eslint-disable-next-line react/no-find-dom-node
//     const currentNode = ReactDom.findDOMNode(this);
//     const childNode = currentNode.parentElement.getElementsByClassName('infinite-queue')[0];
//     childNode.removeEventListener('touchstart', this.onTouchStart);
//     childNode.removeEventListener('touchmove', this.onTouchMove, { passive: false });
//     childNode.removeEventListener('touchend', this.onTouchEnd);
//     childNode.removeEventListener('mousedown', this.onTouchStart);
//     childNode.removeEventListener('mousemove', this.onTouchMove);
//     childNode.removeEventListener('mouseup', this.onTouchEnd);
//
//     clearTimeout(this.refreshCompletedTimeout);
//     clearTimeout(this.resetTimeout);
//   }
//   refresh = () => {
//     this.ignoreTouches = true;
//     this.setState({ status: 'refreshing' }, () => {
//       this.props.onRefresh(() => {
//         this.setState({ status: 'refreshCompleted', height: 0 }, () => {
//           this.reset(0);
//         });
//       });
//     });
//   };
//   onTouchStart = (e) => {
//     if (this.props.disabled || this.ignoreTouches) return;
//     if (this.state.status === 'ready' && this.props.shouldPullToRefresh(e)) {
//       this.pullStartY = e.type === 'mousedown' ? e.pageY : e.touches[0].screenY;
//     } else {
//       this.pullStartY = null;
//     }
//   };
//   onTouchMove = (e) => {
//     if (this.props.disabled || this.ignoreTouches || this.pullStartY === null) return;
//     this.pullMoveY = e.type === 'mousemove' ? e.pageY : e.touches[0].screenY;
//     this.dist = this.pullMoveY - this.pullStartY;
//
//     if (this.dist > 0) {
//       e.preventDefault();
//
//       this.distResisted = Math.min(this.dist / this.props.resistance, this.props.distThreshold);
//
//       this.setState({ status: 'pulling', height: this.distResisted }, () => {
//         if (this.distResisted === this.props.distThreshold) this.refresh();
//       });
//     }
//   };
//   reset = (delay = 0) => {
//     this.resetTimeout = setTimeout(() => {
//       if (this.updater.isMounted(this)) {
//         this.clearTouchStatus();
//         this.setState({ status: 'ready' });
//       }
//     }, delay);
//   };
// }
//
// CustomPullable.defaultProps = {
//   shouldPullToRefresh: (event) => {
//     const queueDom = event.composedPath().find(e => e.classList.contains('infinite-queue'));
//     if (queueDom) {
//       return queueDom.scrollTop === 0;
//     }
//     return false;
//   },
//   resistance: 2.5,
//   distThreshold: 72,
// };

/**
 * Inherited component from ReactRefreshInfiniteTableView that allows to apply infinite scroll
 * effect to a list group queue
 */
class InfiniteQueue extends ReactRefreshInfiniteTableView {
  render() {
    let cells;
    if (this.props.dataSource.length === 0 && !this.props.isLoadingData) {
      cells = (
        <ListGroupItem className="queue-empty-item">
          {this.props.emptyItem}
        </ListGroupItem>
      );
    } else {
      cells = this.props.dataSource.map(item => (
        <ListGroupItem key={item[this.props.identifierProp]}>
          {this.props.handleRenderItem(item)}
        </ListGroupItem>
      ));
    }

    return (
      <div className="infinite-queue" style={{ height: this.props.queueHeight }} onScroll={this.viewDidScroll}>
        <ListGroup>
          {cells}
          {this.loadMoreIndicator()}
          {this.goBackToTop()}
        </ListGroup>
      </div>
    );
  }

  /**
   * Customizes the loading spinner for the infinite scroll behavior
   * @returns {string|*}
   */
  loadMoreIndicator() {
    if (this.state.isLoadingMore) {
      return (
        <ListGroupItem className="list-group-item text-center indicator">
          <Spinner color="primary" />
        </ListGroupItem>
      );
    }
    return '';
  }

  goBackToTop() {
    if (!this.props.hasMore && this.props.dataSource.length !== 0) {
      return (
        <ListGroupItem
          className="back-to-top text-center"
          onClick={event => event.currentTarget.parentElement.parentElement.scrollTo(0, 0)}
        >
          <i className="fa fa-arrow-up" /><span> Back to Top</span>
        </ListGroupItem>
      );
    }
    return '';
  }
}

InfiniteQueue.defaultProps = {
  useDefaultIndicator: false,
};

const Queue = props => (
  <div className="queue">
      <InfiniteQueue
        dataSource={props.dataSource}
        onScrollToBottom={props.handleScrollToBottom}
        handleRenderItem={props.handleRenderItem}
        isLoadingData={props.isLoadingData}
        hasMore={props.hasMore}
        identifierProp={props.identifierProp}
        emptyItem={props.emptyItem}
        queueHeight={props.queueHeight}
      />
  </div>
);

Queue.defaultProps = {
  identifierProp: 'id',
  emptyItem: (
    <div className="text-container">
      <h4 className="text-center">No items found</h4>
      <p className="text-center">
        Pull down to refresh
      </p>
    </div>
  ),
  queueHeight: '91vh',
};

Queue.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleScrollToTop: PropTypes.func.isRequired,
  handleScrollToBottom: PropTypes.func.isRequired,
  handleRenderItem: PropTypes.func.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  identifierProp: PropTypes.string,
  emptyItem: PropTypes.node,
  queueHeight: PropTypes.string,
};

export default Queue;
