import React from 'react';
import PropTypes from 'prop-types';

const ErrorText = ({ children, visible, className }) => (visible ? (
  <div className={className} data-auto="error-text">
    <strong className="color-error">
      <i className="fa fa-exclamation-triangle" />
      &nbsp;{children}
    </strong>
  </div>
) : null);

ErrorText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  visible: PropTypes.bool,
  className: PropTypes.string,
};

ErrorText.defaultProps = {
  visible: true,
  className: '',
};

export default ErrorText;
