import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BackButton from '../common/back-button/BackButton';

import {
  selectIsSideDrawerOpen,
  setIsSideDrawerOpen,
  selectHeaderTitle,
} from '../../state';

const SideDrawerBtn = (props) => {
  const handleClick = () => {
    const {
      isSideDrawerOpen,
      setDrawerOpen,
    } = props;
    setDrawerOpen(!isSideDrawerOpen);
  };
  const renderMenu = (title) => {
    if (title) {
      if (typeof title === 'object') {
        return (<BackButton url={title.pathUrl} props />);
      }
    }
    if (typeof title === 'string') {
      return (
        <button
          className="hamburger"
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
          data-auto="show_left_menu"
          onClick={() => {
            handleClick();
          }}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>);
    }
    return null;
  };
  return (
    renderMenu(props.title)
  );
};

SideDrawerBtn.propTypes = {
  isSideDrawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

function mapStateToProps(state) {
  return {
    isSideDrawerOpen: selectIsSideDrawerOpen(state),
    title: selectHeaderTitle(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setDrawerOpen: setIsSideDrawerOpen,
  }, dispatch);
}
SideDrawerBtn.defaultProps = {
  title: '',
};
export default connect(mapStateToProps, mapDispatchToProps)(SideDrawerBtn);
