import { Col, Container, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import EconsultModalAttachment from '../../econsult-attachment-modal/EconsultModalAttachment';


const PreviousEConsultsBody = ({ eConsult, desktopVersion }) => (
  <Container className="p-0">
    <Row className="p-1">
      <Col>
        {desktopVersion ? <h4 className="font-weight-bolder mb-4">Previous eConsults</h4> :
        <h5 className="font-weight-lighter">Previous eConsults ({eConsult.previousEConsults.length})
        </h5>
        }
        <div className={desktopVersion ? 'attachments-container' : 'attachments-container p-0'} data-auto="previous-econsult-container">
          {eConsult.previousEConsults.length !== 0 ? (
            eConsult.previousEConsults.map(previousEConsult => (
              <EconsultModalAttachment
                key={`attachment-container-${previousEConsult.econsult}`}
                isPreviousEConsult
                attachment={{
                  id: previousEConsult.econsult,
                  name: desktopVersion ? previousEConsult.specialty : `${snakeCase(previousEConsult.specialty)}_econsult_${previousEConsult.econsult}.pdf`,
                  pages: desktopVersion ? `econsult_${previousEConsult.econsult}.pdf` : null,
                }}
                eConsult={eConsult}
                desktopVersion={desktopVersion}
              />
            ))
          ) :
            (<p className={desktopVersion ? 'font-size-larger' : ''}> No previous eConsults for this patient </p>)
          }
        </div>
      </Col>
    </Row>
  </Container>
);

PreviousEConsultsBody.propTypes = {
  eConsult: PropTypes.shape({
    patient_name: PropTypes.string.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    previousEConsults: PropTypes.arrayOf(PropTypes.shape({
      econsult: PropTypes.number,
      specialty: PropTypes.string,
      desktopVersion: PropTypes.bool,
    })),
  }).isRequired,
  desktopVersion: PropTypes.bool,
};

PreviousEConsultsBody.defaultProps = {
  desktopVersion: false,
};

export default PreviousEConsultsBody;
