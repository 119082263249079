import React from 'react';
import PropTypes from 'prop-types';
import {
  formatPatientGender,
  formatAge,
} from '../../../utils/formatters/econsults';


const EconsultTitle = ({ eConsult, desktopVersion, attachmentName }) => {
  const age = formatAge(eConsult.patient_age_in_days);
  const gender = formatPatientGender(eConsult.patient_gender);
  const isEConsult = eConsult.patient_name && eConsult.patient_age_in_days &&
    eConsult.patient_gender;
  const addOnHeader = desktopVersion && attachmentName;
  return (
    <React.Fragment>
      {isEConsult ? (
        <div className={desktopVersion ? 'text-left text-truncate w-inherit px-5' : 'text-center'}>
          <span className={desktopVersion ? 'm-0 font-weight-bold font-size-larger' : 'm-0 text-truncate font-weight-bold d-block'} data-auto="econsult-title-patient-name">
            {eConsult.patient_name}
          </span>
          <span className={desktopVersion ? 'm-0 pl-2 font-weight-bold font-size-larger' : 'm-0 d-block'} data-auto="econsult-title-patient-age-gender">{age},&nbsp;
            {gender}
          </span>
          <span className={addOnHeader ? 'm-0 pl-2 font-weight-bold font-size-larger' : 'd-none'} data-auto="econsult-title-attachment-name">{`> Attachment > ${attachmentName}`}</span>
        </div>
      ) : null}
    </React.Fragment>
  );
};

EconsultTitle.propTypes = {
  eConsult: PropTypes.shape({
    patient_name: PropTypes.string.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
  }),
  desktopVersion: PropTypes.bool,
  attachmentName: PropTypes.string,
};
EconsultTitle.defaultProps = {
  eConsult: {},
  desktopVersion: false,
  attachmentName: '',
};

export default EconsultTitle;
