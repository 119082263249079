import { takeEvery } from 'redux-saga/effects';
import fetchPractitioner from './fetch-practitioner';
import fetchPractitionerSchedule from './fetch-practitioner-schedule';
import savePractitionerSchedule from './save-practitioner-schedule';

const setSagaNamespace = sagaName => `practitioner/${sagaName}`;

export default function* practitionerSaga() {
  return [
    yield takeEvery(setSagaNamespace('FETCH_PRACTITIONER'), fetchPractitioner),
    yield takeEvery(setSagaNamespace('FETCH_PRACTITIONER_SCHEDULE'), fetchPractitionerSchedule),
    yield takeEvery(setSagaNamespace('SAVE_PRACTITIONER_SCHEDULE'), savePractitionerSchedule),
  ];
}
