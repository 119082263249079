/* if this rule is enabled object destructuring won't work for lines 28, 27, 20, and 19 */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import SeparatorTitle from '../../common/separator-title/SeparatorTitle';
import UserFullName from '../../common/user-full-name/UserFullName';
import { specialistSignature, specialistName } from '../../../utils/formatters/econsults';
import { dateWithTimezoneFormat } from '../../../utils/formatters/date';
import axios from '../../../services/axios';

const SummaryResponse = ({ disposition, desktopVersion, visitUrgency, disclaimerID }) => {
  const [disclaimerText, setDisclaimerText] = useState(null);
  const fetchDisclaimer = () => (
    axios.get(`disclaimers/${disclaimerID}`).then(({data})=>{
      setDisclaimerText(data.text);
    })
  );
  useEffect(() => {
    if (!disclaimerText && disclaimerID >= 2) {
      fetchDisclaimer();
    }
  },[])
  const SUMMARY_LABELS = {
    ok_to_treat: 'Treatment options available at the PCP level',
    face_to_face: 'In-person visit with specialist \n' +
      'recommended.',
    pending_requester_feedback: 'I need more info to provide a care plan',
    wrong_specialty: `This is not a ${disposition.specialty_name} case`,
  };
  const { first_name, last_name, suffix } = disposition.specialist_info;
  const signature = specialistSignature(first_name, last_name, suffix);
  const respondedAtFormatted = dateWithTimezoneFormat(disposition.disposition_date);
  return (
    <Row>
      <Col data-auto={`summary-response-${disposition.appropriateness}`}>
        <SeparatorTitle title="Response" isLocked />
        <p className="text-center font-size-smaller" data-auto="disposition-date-field">{respondedAtFormatted}</p>
        <UserFullName
          photoUrl={disposition.specialist_info.profile_photo}
          firstName={first_name}
          lastName={last_name}
          suffix={suffix}
          userTitle={disposition.specialist_info.specialties}
          userNameCallbackFunction={specialistName}
          desktopVersion={desktopVersion}
        />
        <Row className="p-1 response-recommendation">
          <Col xs="12">
            <h5 className="font-weight-lighter">Care Plan Advice</h5>
            <p data-auto="care-plan-advice" className="ql-editor h-auto mb-0">
              {parse(disposition.recommendation)}
            </p>
          </Col>
        </Row>
        {visitUrgency !== '' ? (
          <Row className="p-1 recommended-visit-urgency">
            <Col xs="12">
              <h5 className="font-weight-lighter">Recommended Visit Urgency</h5>
              <p data-auto="visit-urgency-as-text" className="px-3">
                {visitUrgency}
              </p>
            </Col>
          </Row>
        ) : null}
        <Row className="p-1 response-summary">
          <Col xs="12">
            <h5 className="font-weight-lighter">Summary</h5>
            <div className="d-inline-block ml-3">
              <i className={`aristamd-icon icon-5 ${disposition.appropriateness}`} data-auto="response-summary-icon" />
            </div>
            <div className="d-inline-block w-75 ml-3">
              <p data-auto="response-summary-field">
                {SUMMARY_LABELS[disposition.appropriateness]}
              </p>
            </div>
          </Col>
        </Row>
        <div className="specialist-signature" data-auto="signature-section">
          <div className="w-100 font-size-larger">
            <div className="float-right pr-2 mb-4">
              <p className="signature m-0">{signature}</p>
              <hr className="m-0 border" />
              <p className="m-0 text-center">{signature}</p>
            </div>
          </div>
        </div>
        {
          disclaimerText &&
          <div className="mt-5">
            <h5 className="font-weight-lighter">Disclosure</h5>
            <div class="panel-disclosure">
              <p>{ disclaimerText }</p>
            </div>
        </div>
        }
      </Col>
    </Row>
  );
};

SummaryResponse.propTypes = {
  disposition: PropTypes.shape({
    recommendation: PropTypes.string,
    appropriateness: PropTypes.string,
    disposition_date: PropTypes.shape({
      date: PropTypes.string,
    }),
    specialist_info: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      suffix: PropTypes.string,
      profile_photo: PropTypes.string,
      specialties: PropTypes.string,
    }),
  }).isRequired,
  desktopVersion: PropTypes.bool,
  visitUrgency: PropTypes.string,
};

SummaryResponse.defaultProps = {
  desktopVersion: false,
  visitUrgency: '',
};

export default SummaryResponse;
