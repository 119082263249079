import React from 'react';
import PropTypes from 'prop-types';
import BigRadioButton from '../big-radio-button/BigRadioButton';


const BigRadioGroup = ({
  options,
  name,
  onChange,
  innerRef,
  invalid,
  className,
  disabled,
}) => (
  <div className={className}>
      {options.map((option, index) => (
        <React.Fragment key={index}>
        <BigRadioButton
          onChange={onChange}
          name={name}
          value={option.value}
          key={`option-${option.value}`}
          innerRef={innerRef}
          invalid={invalid}
          disabled={disabled}
        >
          {option.text}
        </BigRadioButton>
        {option.underContent ? option.underContent : null}
      </React.Fragment>
    ))}
  </div>
);

BigRadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  innerRef: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

BigRadioGroup.defaultProps = {
  onChange: () => {},
  className: 'options-container',
  disabled: false,
};

export default BigRadioGroup;
