import _ from 'lodash';
import axios from '../services/axios';

export default class APICallBase {
  /**
   * How many recors should be fetched by default
   * @type {number}
   */
  static DEFAULT_LIMIT = 10;

  /**
   * Class default axios instance
   * @type {*}
   */
  axiosInstance = axios;

  /**
   * Class constructor
   */
  constructor() {
    this.params = {};
  }

  /**
   * This method is used to fetch all the data based of a set of parameters
   * @param {object} params parameters used to filter
   * @param {string|null} urlAppend In case the url must be extended
   * @returns {APICallBase} self instance
   */
  get(params = {}, urlAppend = null) {
    this.#validateMethodExistance();
    if (urlAppend) {
      this.url = `${this.url}/${urlAppend}`;
    }
    this.params = params;
    this.method = 'get';
    return this;
  }

  /**
   * This method is used to find an entity by its id also a set of parameters can be provider
   * @param {int} id record id
   * @param {object} params parameters used to filter
   * @returns {Promise} Promise to be fulfilled
   */
  find(id, params = {}) {
    this.url = `${this.url}/${id}`;
    return this.get(params).call();
  }

  /**
   * This method is used to post a set of data to the server in order to create a record
   * @param {object} body info to be sent to the server
   * @returns {Promise} Promise to be fulfilled
   */
  post(body) {
    this.#validateMethodExistance();
    this.method = 'post';
    this.body = body;
    return this.call();
  }

  /**
   * This method is used to update an specific record based on its id
   * @param {int} id record id
   * @param {object} body updated info to be sent to the server
   * @returns {Promise} Promise to be fulfilled
   */
  put(id, body) {
    this.#validateMethodExistance();
    this.url = `${this.url}/${id}`;
    this.method = 'put';
    this.body = body;
    return this.call();
  }

  /**
   * This method is used to patch an specific record based on its id
   * @param {int} id record id
   * @param {object} body updated info to be sent to the server
   * @returns {Promise} Promise to be fulfilled
   */
  patch(id, body) {
    this.#validateMethodExistance();
    this.url = `${this.url}/${id}`;
    this.method = 'patch';
    this.body = body;
    return this.call();
  }

  /**
   * This method is used to delete an specific record based on its id
   * @param {int} id record id
   * @returns {Promise} Promise to be fulfilled
   */
  delete(id) {
    this.#validateMethodExistance();
    this.url = `${this.url}/${id}`;
    this.method = 'delete';
    return this.call();
  }

  /**
   * This method sets the offset and limit in order to retrieve a specific section of the records
   * @param {int} page page number
   * @param {int} limit how many records must be fetched (10 by default)
   * @returns {APICallBase} self instance
   */
  getPage(page, limit = APICallBase.DEFAULT_LIMIT) {
    if (this.method !== 'get') {
      throw new Error('This can be used only with GET method');
    }
    const start = (page - 1) * limit;
    this.params = {
      ...this.params,
      start,
      limit,
    };
    return this;
  }

  /**
   * Allows to order the records by an specific field
   * @param {string} field field name
   * @param {string} direction which is the sort direction (asc by default)
   * @returns {APICallBase} self instance
   */
  sortBy(field, direction = 'asc') {
    if (this.method !== 'get') {
      throw new Error('This can be used only with GET method');
    }
    this.params = {
      ...this.params,
      orderBy: field,
      orderDirection: direction,
    };
    return this;
  }

  /**
   * buids the parameter query based on an object
   * @param {object} params
   * @returns {string} parameters as string
   */
  #addParams = (params) => {
    if (!_.isEmpty(params) || !_.isNull(params)) {
        let searchParams = new URLSearchParams(params);
        return `?${searchParams.toString()}`;
    }
    return '';
  };

  /**
   * Validates that two or more methods cannot be called in sequence:
   *  Example: call get and post at the same time
   * @throws Error if one method is being used with other method
   */
  #validateMethodExistance = () => {
    if (this.method) {
      throw new Error(`Method ${_.title(this.method)} cannot be applied with other methods`);
    }
  };

  /**
   * Triggers the axios service and returns the promise to be fullfilled
   * @returns {Promise}
   */
  call() {
    const url = this.method === 'get' ? `${this.url}${this.#addParams(this.params)}` : this.url;
    return _.isUndefined(this.body) ? this.axiosInstance[this.method](url) :
      this.axiosInstance[this.method](url, this.body);
  }
}
