import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './app';
import browser from './browser';
import idleModal from './idleModal';
import user from './user/user';
import sideDrawer from './sideDrawer';
import eConsultsQueue from './eConsultsQueue';
import headerText from './headerText';
import eConsult from './eConsult';
import practitioner from './practitioner';
import starRatingQuestionSet from './starRatingQuestionSet';
import asset from './asset/asset';

export default history => combineReducers({
  router: connectRouter(history),
  app,
  asset,
  browser,
  idleModal,
  user,
  eConsult,
  eConsultsQueue,
  headerText,
  sideDrawer,
  practitioner,
  starRatingQuestionSet,
});
