import axios from 'axios';
import Session from './Session';

export const configure = ({
  baseURL,
}) => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
};

export const updateAccessToken = (token) => {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
};

export const updateOrganization = (organizationId) => {
  axios.defaults.headers.organization = organizationId;
};

/**
 * Handles the unauthorized requests in order to clean up the session
 * @returns {Promise}
 */
const handleUnauthorized = () => {
  Session.unauthorized();
  return Promise.resolve();
};

axios.interceptors.response.use(config => config, (error) => {
  const { response: { status } } = error;
  const code = error?.response?.data?.code || null;
  switch (status) {
    case 401:
      return handleUnauthorized();
    case 417:
      if (code === 'needs_2fa') {
        Session.redirectTwoFactorAuth();
        return Promise.reject(error);
      }
    default:
      return Promise.reject(error);
  }
});

export default axios;
