import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactstrapModal, ModalBody } from 'reactstrap';
import Form from '../form/Form';
import CustomModalHeader from '../../../modal-header/ModalHeader';

const Modal = ({
  eConsult,
  isOpen,
  toggle,
  onClosed,
  desktopVersion,
}) => (
  <ReactstrapModal
    isOpen={isOpen}
    onClosed={onClosed}
    toggle={toggle}
    className={!desktopVersion ? 'mw-100 h-100 m-0 full-screen-modal' : ''}
    data-auto="specialty-review-modal"
    id="specialty-review-modal"
  >
    <CustomModalHeader {...{ toggle }} helpIconOnly data-auto="page-title">
      <h5 className="m-0 letter-spacing-fixed">
        Submit Feedback
      </h5>
    </CustomModalHeader>
    <ModalBody className="mw-100 h-100 overflow-auto pb-4">
      <Form eConsult={eConsult} onSubmitted={toggle} />
    </ModalBody>
  </ReactstrapModal>
);

Modal.propTypes = {
  eConsult: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  desktopVersion: PropTypes.bool,
};

Modal.defaultProps = {
  desktopVersion: false,
};

export default Modal;

