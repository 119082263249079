import { put, select } from 'redux-saga/effects';
import {
  setEConsult,
  setIsFetchingEConsult,
  setEConsultError,
  selectIsFetchingEConsult,
} from '../../state/eConsult';
import EConsults from '../../api-calls/EConsults';


/**
 * Reset Econsult redux states
 * @returns {IterableIterator<*>} next iteration
 */
function* resetState() {
  yield put(setEConsult(null));
  yield put(setEConsultError(null));
}

/**
 * Verifies if server is already getting the econsult
 * @returns {IterableIterator<*>} next iteration
 */
function* isNotFetchingEconsult() {
  return yield select(state => !selectIsFetchingEConsult(state));
}

/**
 * Calls the server API in order to fetch the econsult info
 * @param eConsultId
 * @returns {IterableIterator<Promise|*>} next iteration
 */
function* doServerCall(eConsultId) {
  try {
    const response = yield EConsults().find(eConsultId);
    const previousEConsults = yield EConsults().getPatientHistory(eConsultId);
    const comments = yield EConsults().getComments(eConsultId);
    response.data.previousEConsults = previousEConsults.data;
    response.data.comments = comments.data;
    yield put(setEConsult(response.data));
  } catch (e) {
    yield put(setEConsultError({
      message: e.response.data.message,
      status: e.response.status,
    }));
  }
}

export default function* fetchEConsult(action) {
  if (yield* isNotFetchingEconsult()) {
    yield* resetState();
    yield put(setIsFetchingEConsult(true));
    yield* doServerCall(action.eConsultId);
    yield put(setIsFetchingEConsult(false));
  }
}
