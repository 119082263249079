import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { isDisabled, isInvalid } from '../../../utils/formatters/cssClass';

const SpecialtySelector = ({
  apiCallback,
  className,
  name,
  disabled,
  invalid,
  value,
  clearAfterDisable,
  dataAut,
}) => {
  /**
   * Defines what are the specialties for the select component
   */
  const [specialties, setSpecialties] = useState([]);

  /**
   * React hook form instance from parent component
   */
  const {
    setValue,
    register,
    errors,
    clearErrors,
    formState,
  } = useFormContext();

  /**
   * Defines What is the current selected option in the dropdown
   */
  const [currentOption, setCurrentOption] = useState(null);

  /**
   * Defines if api callback was already triggered
   */
  const [apiCalled, setApiCalled] = useState(false);

  const disabledRef = useRef(disabled);

  /**
   * Using the specialties callback formats the specialties in order to create options
   * for Dropdown
   * @return {void}
   */
  const fetchSpecialties = () => {
    apiCallback().then(({ data }) => setSpecialties(data.map(specialty => (
      {
        label: specialty.display_name,
        options: specialty.subspecialties.map(subSpecialty => (
          {
            label: subSpecialty.display_name,
            value: subSpecialty.code,
          })),
      }))), () => {});
  };

  /**
   * This method is called when there is a change in the dropdown in order to set the specialty
   * @param event
   */
  const setSpecialtyOption = (event) => {
    // Sets the current option inside state
    setCurrentOption(event);
    // Sets the current option in the form hook object
    setValue(name, event.value, { shouldValidate: formState.isSubmitted });
    // Clears any error related with the specialty field in the form hook object
    if (errors[name]) {
      clearErrors(name);
    }
  };

  useEffect(() => {
    register({ name: 'recommended_specialty' }, { validate: (valueToValidate) => !_.isEmpty(valueToValidate) || disabledRef.current });
  }, []);

  useEffect(() => {
    // Checks if the data should be retrieved in order to populate the dropdown
    if (specialties.length === 0 && !apiCalled && (!disabled || !clearAfterDisable)) {
      fetchSpecialties();
      setApiCalled(true);
    }
    // Sets the value in case it was stored before and the specialty options are loaded
    if (specialties.length !== 0 && value) {
      setCurrentOption(specialties.flatMap(group => group.options)
        .find(specialty => specialty.value === value) || null);
    }
    // In case the dropdown is disabled the current option must be cleared
    if (currentOption && !value && disabled && clearAfterDisable) {
      setCurrentOption(null);
    }
    // Clears the value in the form since it won't be available or the
    // selected option is not available
    if (apiCalled && value && (specialties.length === 0 || !currentOption)) {
      setValue(name, null, { shouldValidate: formState.isSubmitted });
    }
    // Re-validate if field is disabled and none value is selected
    if (errors.recommended_specialty && disabled && !value) {
      setValue(name, null, { shouldValidate: formState.isSubmitted });
    }
    disabledRef.current = !!disabled;
  }, [disabled, invalid, specialties, value]);

  const isInvalidClass = isInvalid(invalid);
  const isDisabledClass = isDisabled(disabled);
  return (<Select
    options={specialties}
    placeholder="Select Specialty"
    className={`${className} react-select${isInvalidClass}${isDisabledClass}`}
    name={name}
    isDisabled={disabled}
    value={currentOption}
    data-auto={dataAut}
    onChange={setSpecialtyOption}
  />);
};

SpecialtySelector.propTypes = {
  apiCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  value: PropTypes.string,
  clearAfterDisable: PropTypes.bool,
  dataAut: PropTypes.string,
};

SpecialtySelector.defaultProps = {
  className: 'specialty-selector',
  disabled: false,
  value: null,
  clearAfterDisable: true,
  dataAut: 'specialty-selector',
};

export default SpecialtySelector;
