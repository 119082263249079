import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';


const SeparatorTitle = ({ title, isLocked }) => (
  <div className={`text-center mx-auto separator-title-container p-2${isLocked ? ' locked' : ''}`}>
    <Badge
      className={isLocked ? 'separator-title-text-locked' : ''}
      pill
      color={isLocked ? '' : 'primary'}
    >
      {title}
    </Badge>
  </div>
);

SeparatorTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
};

SeparatorTitle.defaultProps = {
  isLocked: false,
};

export default SeparatorTitle;
