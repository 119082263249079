import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

const BigRadioButton = ({
  name,
  value,
  onChange,
  children,
  innerRef,
  invalid,
  disabled,
}) => (
  <FormGroup check>
    <Label className="big-radio-button-container" check>
      <div className="big-radio-button-text pr-3">{children}</div>
      <Input
        type="radio"
        name={name}
        value={value}
        data-auto={`big-radio-${name}-${value}`}
        className="big-radio-button-input"
        onChange={onChange}
        innerRef={innerRef}
        invalid={invalid}
        disabled={disabled}
      />
      <span className="big-radio-button-checkmark" />
    </Label>
  </FormGroup>
);

BigRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  innerRef: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

BigRadioButton.defaultProps = {
  disabled: false,
};

export default BigRadioButton;
