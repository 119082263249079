import { camelCase } from 'lodash';

export default class Base {
  requiredAttributes = []
  constructor(fhirResource) {
    this.resource = fhirResource;
  }
  /**
   * Checks if provider resource can be parsed by the class
   */
  validateResource() {
    if (this.resource.resourceType !== this.className) {
      throw new Error(`Expected resource ${this.constructor.name}, received ${this.resource?.resourceType || 'Unknown'}`);
    }
  }

  /**
   * Transforms the FHIR resource into a normal resource based on the parsers and
   * required attributes
   * @return {{}} parsed resource
   */
  transform() {
    this.validateResource.bind(this)();
    const parsedAttributes = {};
    const parsers = Object.getOwnPropertyNames(Object.getPrototypeOf(this)).filter(f => f.startsWith('parse'));
    parsers.forEach((parser) => {
      const parsedValue = this[parser].bind(this)();
      if (parsedValue) {
        parsedAttributes[camelCase(parser.replace('parse', ''))] = parsedValue;
      }
    });
    this.requiredAttributes.forEach((attribute) => {
      if (this.resource[attribute]) {
        parsedAttributes[attribute] = this.resource[attribute];
      } else {
        throw new Error(`Attribute ${attribute} must be present in this resource`);
      }
    });
    return parsedAttributes;
  }

  getExtensionValue(extensionUrls, extension = this.resource.extension) {
    const urls = typeof extensionUrls === 'string' ? [extensionUrls] : extensionUrls;
    const url = urls.shift();
    const childExtension = extension.find(e => e.url === url);
    let value = null;
    if (urls.length > 0 && childExtension.extension) {
      value = this.getExtensionValue(urls, childExtension.extension);
    }
    if (childExtension
    ) {
      const valueKey = Object.keys(childExtension).find(k => k.startsWith('value'));
      if (valueKey) {
        value = childExtension[valueKey];
      }
    }
    return value;
  }

  setExtensionValue(extensionUrls, extension, newValue) {
    const urls = typeof extensionUrls === 'string' ? [extensionUrls] : extensionUrls;
    const url = urls.shift();
    const childExtension = extension.find(e => e.url === url);

    if (urls.length > 0 && childExtension.extension) {
      this.setExtensionValue(urls, childExtension.extension, newValue);
    }
    if (childExtension
    ) {
      const valueKey = Object.keys(childExtension).find(k => k.startsWith('value'));
      if (valueKey) {
        childExtension[valueKey] = newValue;
      }
    }
  }
}
