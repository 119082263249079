import createReducer from './createReducer';

/** ********************************************
 * constants
 ******************************************** */

export const STAR_RATING_QUESTION_SET = 'starRatingQuestionSet/STAR_RATING_QUESTION_SET';
export const FETCH_STAR_RATING_QUESTION_SET = 'starRatingQuestionSet/FETCH_STAR_RATING_QUESTION_SET';
export const IS_FETCHING_STAR_RATING_QUESTION_SET = 'starRatingQuestionSet/IS_FETCHING_STAR_RATING_QUESTION_SET';
export const STAR_RATING_QUESTION_SET_ERROR = 'starRatingQuestionSet/STAR_RATING_QUESTION_SET_ERROR';

/** ********************************************
 * reducer
 ******************************************** */

export const initialStarRatingQuestionSetState = {
  starRatingQuestionSet: null,
  isFetchingStarRatingQuestionSet: false,
  starRatingQuestionSetError: null,
};

export default createReducer(initialStarRatingQuestionSetState, {
  [STAR_RATING_QUESTION_SET]: (state, { payload }) => ({ starRatingQuestionSet: payload }),
  [IS_FETCHING_STAR_RATING_QUESTION_SET]: (state, { payload }) => (
    { isFetchingStarRatingQuestionSet: payload }
  ),
  [STAR_RATING_QUESTION_SET_ERROR]: (state, { payload }) => (
    { starRatingQuestionSetError: payload }
  ),
});

/** ********************************************
 * actions
 ******************************************** */

/**
 * @param {object} starRatingQuestionSet
 * @return {{type: string, payload: *}}
 */
export const setStarRatingQuestionSet = starRatingQuestionSet => ({
  type: STAR_RATING_QUESTION_SET,
  payload: starRatingQuestionSet,
});

/**
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setIsFetchingStarRatingQuestionSet = bool => ({
  type: IS_FETCHING_STAR_RATING_QUESTION_SET,
  payload: bool,
});

/**
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setStarRatingQuestionSetError = bool => ({
  type: STAR_RATING_QUESTION_SET_ERROR,
  payload: bool,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param {object} state
 * @return {object}
 */
export const selectStarRatingQuestionSet = state =>
  state.starRatingQuestionSet.starRatingQuestionSet;

/**
 * @param {object} state
 * @return {boolean}
 */
export const selectIsFetchingStarRatingQuestionSet = state =>
  state.starRatingQuestionSet.isFetchingStarRatingQuestionSet;

/**
 * @param {object} state
 * @return {boolean}
 */
export const selectStarRatingQuestionSetError = state =>
  state.starRatingQuestionSet.starRatingQuestionSetError;
