import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Error from '../../common/error/Error';
import { errorMessageNotification } from '../../../utils/formatters/econsults';
import { setIsSideDrawerOpen } from '../../../state';
import store from '../../../store';

const EConsultErrorNotification = ({
  goBackCallback,
  error,
  eConsultId,
  desktopVersion,
}) => {
  const messageError = errorMessageNotification(error);
  const setDrawerDesktopBehaviour = () => {
    if (desktopVersion) {
      store.dispatch(setIsSideDrawerOpen(true));
    }
  };

  return (
    <div>
      <Error
        message={(
          <div>
            {parse(messageError.message)}
          </div>
        )}
        title={messageError.title}
      />
      <div className="text-center ml-3 mr-3">
        <Button
          color="primary"
          className="w-100 pt-3 pb-3"
          data-auto="specialist-mobile-error-back-to-Requests"
          onClick={() => {
            goBackCallback();
            setDrawerDesktopBehaviour();
          }}
        >
          Go to My Requests queue
        </Button>
      </div>
    </div>
  );
};

EConsultErrorNotification.propTypes = {
  goBackCallback: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.number,
  }),
  eConsultId: PropTypes.string,
  desktopVersion: PropTypes.bool,
};

EConsultErrorNotification.defaultProps = {
  error: {},
  eConsultId: null,
  desktopVersion: false,
};


export default EConsultErrorNotification;
