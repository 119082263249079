import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';
import { isSupportedVideoFile } from '../../utils/index';
import { assetUrl } from '../../utils/formatters/attachments';
import { fetchAssetPreviewStatus } from '../../state';

const FileViewer = ({ file, urlPreview }) => {
  const dispatch = useDispatch();
  const [displayType, setDisplayType] = useState('loading');
  const [errorMessage, setErrorMessage] = useState(null);
  const fileType = file?.type ?? file?.mime_type ?? '';

  const {
    isFetchingPreviewStatus,
    isPreviewAvailable,
  } = useSelector((state) => state.asset?.assets[file?.id] || {}, shallowEqual);

  // Runs on first render
  useEffect(
    () => {
      if (isSupportedVideoFile(fileType)) {
        // check preview status
        if (!isPreviewAvailable && !isFetchingPreviewStatus) {
          dispatch(fetchAssetPreviewStatus(file?.id));
        }
      } else if (fileType.includes('video')) {
        // catch video types that are not supported
        setDisplayType('error');
        setErrorMessage('This file cannot be viewed in the browser because the file type is '
          + 'not supported, please convert to .MP4 or .MOV and try again.');
      } else {
        setDisplayType('iframe');
      }
    }, [],
  );

  // Runs on first render and after isPreviewAvailable changes, due to one of the following:
  // - start fetch preview status (isPreviewAvailable is set to undefined)
  // - finish fetch preview status (isPreviewAvailable is set to true/false)
  useEffect(
    () => {
      if (isSupportedVideoFile(fileType)) {
        if (isUndefined(isPreviewAvailable)) {
          setDisplayType('loading');
        } else if (isPreviewAvailable) {
          setDisplayType('video');
        } else {
          setDisplayType('error');
          setErrorMessage('Video preview generation is in progress. Please try again in a few minutes.');
        }
      }
    }, [isPreviewAvailable],
  );

  return (
    <div className="file-viewer">
      {displayType === 'loading' && (
        <p className="alert alert-info text-center" data-auto="file-viewer-loading">
          Loading...
        </p>
      )}
      {displayType === 'video' && (
        <video
          src={`${assetUrl(file?.id)}/preview`}
          type={fileType}
          data-auto="file-viewer-video"
          controls
        />
      )}
      {displayType === 'iframe' && (
        <iframe
          src={urlPreview}
          title={file?.name}
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          webkitallowfullscreen=""
          data-auto="file-viewer-iframe"
        />
      )}
      {displayType === 'error' && (
        <p className="alert alert-danger text-center" data-auto="file-viewer-error">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

FileViewer.propTypes = {
  file: PropTypes.objectOf(PropTypes.any),
  urlPreview: PropTypes.string,
};

FileViewer.defaultProps = {
  file: undefined,
  urlPreview: undefined,
};

export default FileViewer;
