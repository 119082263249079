import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Loading from '../common/loading/Loading';
import store from '../../store';
import {
  selectEConsult,
  selectEConsultError,
  setEConsultError,
  setEConsult,
  selectBrowserViewport,
  setIsSideDrawerOpen,
  selectPreviousPath,
} from '../../state';
import { setTitle, insidePage } from '../../utils';
import EConsultErrorNotification from './econsult-error-notification/EConsultErrorNotification';
import EConsults from '../../api-calls/EConsults';
import EconsultMobileExperience from './mobile-experience/EconsultMobileExperience';
import EconsultDesktopExperience from './desktop-experience/EconsultDesktopExperience';
import { useHistory } from "react-router-dom";

const SUBMITTED_ECONSULT_STATUSES = [
  'submitted',
  'requester_feedback_sent',
  'rerouted',
  'econsult_reassigned',
  'specialist_assigned',
];
const EConsult = ({
  eConsultId,
  eConsult,
  error,
  browserViewport,
  previousPath,
}) => {
  const history = useHistory();
  const isDrawerVisible = () => (browserViewport.greaterThan.medium && insidePage('/'));
  /**
   * Sets econsult status as opened once the specialist opens the econsult
   * @param eConsult
   * @returns {{successCallback: successCallback, econsultId: *, failCallback: failCallback,
   * difference: [{op: string, path: string, value: string}], type: string}}
   */
  const openEConsult = eConsultRecord => store.dispatch({
    type: 'econsultsSaga/UPDATE_ECONSULT',
    econsultId: eConsultRecord.id,
    difference: [{
      op: 'replace',
      path: '/status',
      value: 'open',
    }],
    successCallback: () => {
      EConsults().heartbeat(eConsultId);
    },
    failCallback: () => {},
  });
  const desktopVersion = insidePage('econsults') && browserViewport.greaterThan.medium;
  useEffect(() => {
    /**
     * Here we uses this listener in order to clear the local state for the eConsult when the user
     * uses the browser buttons instead of the app button
     * and also avoid backend error with it tries to apply the transition again
     */
    window.addEventListener('popstate', () => {
      if (!eConsult) {
        store.dispatch(setEConsult(null));
        if (isDrawerVisible()) {
          store.dispatch(setIsSideDrawerOpen(true));
        }
      }
    });
    if (!eConsult) {
      store.dispatch({
        type: 'econsultsSaga/FETCH_ECONSULT',
        eConsultId,
      });
    }
    if (eConsult) {
      setTitle({
        component: 'EconsultTitle',
        props: { eConsult, desktopVersion },
        pathUrl: previousPath,
      });
      if (SUBMITTED_ECONSULT_STATUSES.includes(eConsult.status)) {
        openEConsult(eConsult);
      }
    }
    if (error) {
      setTitle('My Requests');
    }
  }, [eConsult]);
  /**
   * Defines the API callback function in order to retrieve econsult's comments
   * @returns {Promise} api call to execute
   */
  return eConsult ? (
    <div className="h-100">
      {desktopVersion ? <EconsultDesktopExperience eConsult={eConsult} />
      : <EconsultMobileExperience eConsult={eConsult} history={history} /> }
    </div>
  ) :
    <div>
      {
      error ?
        <EConsultErrorNotification
          goBackCallback={() => {
            store.dispatch(setEConsultError(null));
            history.push('/');
          }}
          error={error}
          eConsultId={eConsultId}
          desktopVersion={desktopVersion}
        /> : <Loading />
      }
    </div>;
};

EConsult.propTypes = {
  eConsultId: PropTypes.string.isRequired,
  eConsult: PropTypes.shape({
    appropriateness: PropTypes.string,
    attachments: PropTypes.array,
    disclaimer: PropTypes.bool,
    full_details: PropTypes.shape({
      date_of_birth: PropTypes.string.isRequired,
      patient_id: PropTypes.number.isRequired,
      mrn: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      clinic: PropTypes.string.isRequired,
      masked_id: PropTypes.string.isRequired,
      notes: PropTypes.string,
      assignment_date: PropTypes.string.isRequired,
      disposition_due_date: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    issue_summary: PropTypes.string.isRequired,
    main_question: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    recommendation: PropTypes.string,
    recommended_specialty: PropTypes.string,
    specialist_form_version: PropTypes.string,
    status: PropTypes.string.isRequired,
    needs_disposition: PropTypes.bool.isRequired,
    reroute_history: PropTypes.arrayOf(PropTypes.shape({
      rerouted_at: PropTypes.shape({
        date: PropTypes.string,
      }),
      specialty: PropTypes.string,
    })),
  }),
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.number,
  }),
  browserViewport: PropTypes.shape({
    greaterThan: PropTypes.shape({
      medium: PropTypes.bool,
    }),
  }).isRequired,
  previousPath: PropTypes.string.isRequired,
};

EConsult.defaultProps = {
  eConsult: {},
  error: {},
};

const mapStateToProps = state => ({
  eConsult: selectEConsult(state),
  error: selectEConsultError(state),
  browserViewport: selectBrowserViewport(state),
  previousPath: selectPreviousPath(state),
});

export default connect(mapStateToProps)(EConsult);
