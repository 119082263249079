import React from 'react';
import PropTypes from 'prop-types';
import FullDetailsMobile from './full-details-body/FullDetailsMobile';
import UpperCollapsibleSection
  from '../../common/upper-collapsible-section/UpperCollapsibleSection';

const FullDetails = ({ eConsult }) => {
  return (
    <UpperCollapsibleSection
      getTitle={showDetails => `${!showDetails ? 'Show' : 'Hide'} Full Details`}
      dataAut="full-details-collapsible-link"
    >
      <FullDetailsMobile eConsultDetails={eConsult.full_details} />
    </UpperCollapsibleSection>
  );
};

FullDetails.propTypes = {
  eConsult: PropTypes.shape({
    full_details: PropTypes.shape({
      date_of_birth: PropTypes.string.isRequired,
      patient_id: PropTypes.number.isRequired,
      mrn: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      clinic: PropTypes.string.isRequired,
      masked_id: PropTypes.string.isRequired,
      notes: PropTypes.string,
      assignment_date: PropTypes.string.isRequired,
      disposition_due_date: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default FullDetails;
