import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import SeparatorTitle from '../../common/separator-title/SeparatorTitle';
import RequestBody from '../request-body/RequestBody';
import AttachmentsBody from '../attachments-body/AttachmentsBody';
import FullDetails from '../full-details/FullDetails';
import PreviousEConsults from '../previous-econsults/PreviousEConsults';
import ResponseBody from '../response-body/ResponseBody';
import EConsultEvents from '../econsult-events/EConsultEvents';


const MobileExperience = ({ eConsult }) => (
  <Container className="econsult-container">
    <Row>
      <Col>
        <SeparatorTitle title="Request" isLocked={!eConsult.needs_disposition} />
      </Col>
    </Row>
    <Row>
      <Col>
        <RequestBody eConsult={eConsult} />
        <AttachmentsBody
          eConsult={eConsult}
        />
        <PreviousEConsults eConsult={eConsult} />
        <FullDetails
          eConsult={eConsult}
        />
      </Col>
    </Row>
    <EConsultEvents eConsult={eConsult} />
    {eConsult.needs_disposition ?
      <ResponseBody eConsult={eConsult} /> : null
          }
  </Container>
);


MobileExperience.propTypes = {
  eConsult: PropTypes.shape({
    appropriateness: PropTypes.string,
    attachments: PropTypes.array,
    disclaimer: PropTypes.bool,
    full_details: PropTypes.shape({
      date_of_birth: PropTypes.string.isRequired,
      patient_id: PropTypes.number.isRequired,
      mrn: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      clinic: PropTypes.string.isRequired,
      masked_id: PropTypes.string.isRequired,
      notes: PropTypes.string,
      assignment_date: PropTypes.string.isRequired,
      disposition_due_date: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    issue_summary: PropTypes.string.isRequired,
    main_question: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    recommendation: PropTypes.string,
    recommended_specialty: PropTypes.string,
    specialist_form_version: PropTypes.string,
    status: PropTypes.string.isRequired,
    needs_disposition: PropTypes.bool.isRequired,
    reroute_history: PropTypes.arrayOf(PropTypes.shape({
      rerouted_at: PropTypes.shape({
        date: PropTypes.string,
      }),
      specialty: PropTypes.string,
    })),
  }),
};

MobileExperience.defaultProps = {
  eConsult: {},
};

export default MobileExperience;
