import { put } from 'redux-saga/effects';
import {
  setUser,
  isFetchingUser,
} from '../state';
import Users from '../api-calls/Users';

export default function* fetchUser(action) {
  yield put(isFetchingUser(true));
  try {
    const response = yield Users().get(null, action.payload).call();
    window.Beacon('identify', {
      name: response.data.full_name,
      email: response.data.email,
    });
    // Retrieve SP user data once user is loaded
    yield put(setUser(response.data));
  } catch (e) {
    yield put(setUser(null));
  }
  // Restore original state when user is fetched
  yield put(isFetchingUser(false));
}
