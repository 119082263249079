import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { updateOrganization } from '../../services/axios';
import { selectUserOrganizations, setUserOrganizationId } from '../../state';
import store, { history } from '../../store';
import EnvironmentSettings from '../../services/EnvironmentSettings';

const SelectSite = ({ organizations }) => {
  const [organizationId, setOrganizationId] = useState(organizations[0].id);

  const handleSiteChange = event => setOrganizationId(event.target.value);

  const confirmSite = () => {
    cookie.save(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, organizationId);
    updateOrganization(organizationId);
    store.dispatch(setUserOrganizationId(organizationId));
    history.push('/');
  };

  return (
    <div className="select-site-wrapper">
      <p className="font-weight-bold">Select Site</p>
      <select className="form-control" value={organizationId} onChange={handleSiteChange}>
        {
            organizations ? organizations.map(org => (
              <option key={org.id} value={org.id}>{org.display_name.replace('&amp;', '&')}</option>
            )) : <option>Loading...</option>
          }
      </select>
      <button
        className="form-control btn btn-primary btn-confirm"
        onClick={confirmSite}
      >
          Continue
      </button>
    </div>
  );
};

SelectSite.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    display_name: PropTypes.string.isRequired,
  })),
};

SelectSite.defaultProps = {
  organizations: [],
};

const mapStateToProps = state => ({
  organizations: selectUserOrganizations(state),
});

export default connect(mapStateToProps)(SelectSite);
