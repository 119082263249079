import createReducer from './createReducer';

/** ********************************************
 * constants
 ******************************************** */

export const ECONSULTS = 'eConsultsQueue/ECONSULTS';
export const IS_FETCHING_ECONSULTS = 'eConsultsQueue/IS_FETCHING_ECONSULTS';
export const CURRENT_PAGE = 'eConsultsQueue/CURRENT_PAGE';
export const HAS_MORE = 'eConsultsQueue/HAS_MORE';
export const ECONSULTS_ERROR = 'eConsultsQueue/ECONSULTS_ERROR';

/** ********************************************
 * reducer
 ******************************************** */

export const initialEConsultsQueueState = {
  eConsults: [],
  isFetchingEConsults: false,
  currentPage: 1,
  hasMore: true,
  eConsultsError: null,
};

export default createReducer(initialEConsultsQueueState, {
  [ECONSULTS]: (state, { payload }) => ({ eConsults: payload }),
  [IS_FETCHING_ECONSULTS]: (state, { payload }) => ({ isFetchingEConsults: payload }),
  [CURRENT_PAGE]: (state, { payload }) => ({ currentPage: payload }),
  [HAS_MORE]: (state, { payload }) => ({ hasMore: payload }),
  [ECONSULTS_ERROR]: (state, { payload }) => ({ eConsultsError: payload }),
});

/** ********************************************
 * actions
 ******************************************** */

/**
 *
 * @param {array} eConsults
 * @return {{type: string, payload: *}}
 */
export const setEConsults = eConsults => ({
  type: ECONSULTS,
  payload: eConsults,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setIsFetchingEConsults = bool => ({
  type: IS_FETCHING_ECONSULTS,
  payload: bool,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setCurrentPage = page => ({
  type: CURRENT_PAGE,
  payload: page,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setHasMore = hasMore => ({
  type: HAS_MORE,
  payload: hasMore,
});

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setEConsultsError = eConsultsError => ({
  type: ECONSULTS_ERROR,
  payload: eConsultsError,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {boolean}
 */
export const selectEConsults = state => state.eConsultsQueue.eConsults;

/**
 * @param state
 * @return {boolean}
 */
export const selectIsFetchingEConsults = state => state.eConsultsQueue.isFetchingEConsults;

/**
 * @param state
 * @return {boolean}
 */
export const selectCurrentPage = state => state.eConsultsQueue.currentPage;

/**
 * @param state
 * @return {boolean}
 */
export const selectHasMore = state => state.eConsultsQueue.hasMore;

/**
 * @param state
 * @return {boolean}
 */
export const selectEConsultsError = state => state.eConsultsQueue.eConsultsError;
