import { Col, Container, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  formatPatientGender,
  formatAge,
} from '../../../utils/formatters/econsults';
import { dateWithoutTZFormat } from '../../../utils/formatters/date';
import EConsult from '../../../services/EConsult';

const RequestBody = ({ eConsult, desktopVersion }) => {
  const sourceInfo = EConsult.getSourceInfo(eConsult);
  const isCorrectionalFacility = EConsult.isCorrectionalFacility(eConsult);

  return (
    <Container className="request-container">
      <Row className={desktopVersion ? 'mt-5 pb-3' : 'd-none'}>
        <h4 className="font-weight-bold" >Patient Info</h4>
      </Row>
      <Row className="p-1 patient-name">
        <Col xs={desktopVersion ? '2' : '4'} className="pl-0" >
          <p className="font-weight-lighter m-0">Patient</p>
        </Col>
        <Col xs="8" className={desktopVersion ? 'p-0' : 'pr-0'} >
          <p className="m-0" data-auto="patient-full-name">{eConsult.patient_name}</p>
        </Col>
      </Row>
      <Row className="p-1 patient-age">
        <Col xs={desktopVersion ? '2' : '4'} className="pl-0" >
          <p className="font-weight-lighter m-0">{desktopVersion ? 'Birthday' : 'Age'}</p>
        </Col>
        <Col xs="8" className={desktopVersion ? 'p-0' : 'pr-0'} >
          <div data-auto="patient-age">
            {desktopVersion ? `${dateWithoutTZFormat(eConsult.full_details.date_of_birth)} (${formatAge(eConsult.patient_age_in_days)})`
              : formatAge(eConsult.patient_age_in_days)}
          </div>
        </Col>
      </Row>
      <Row className={`p-1 patient-gender ${!isCorrectionalFacility && !desktopVersion ? 'mb-4' : ''}`}>
        <Col xs={desktopVersion ? '2' : '4'} className="pl-0" >
          <p className="font-weight-lighter m-0">Gender</p>
        </Col>
        <Col xs="8" className={desktopVersion ? 'p-0' : 'pr-0'} >
          <div data-auto="patient-gender">{formatPatientGender(eConsult.patient_gender)}</div>
        </Col>
      </Row>
      {isCorrectionalFacility && !desktopVersion
        ? (
          <Row className="text-primary p-1 font-size-smaller">
            <Col xs="12" className="pl-0 strong">
              <i className="fa fa-exclamation-circle" />
              <span>This patient is currently in a correctional facility.</span>
            </Col>
          </Row>
        )
        : ''}
      <Row className="p-1 clinic">
        <Col xs={desktopVersion ? '2' : '4'} className="pl-0" >
          <p className="font-weight-lighter m-0">{desktopVersion ? 'Patient MRN' : 'Clinic'}</p>
        </Col>
        <Col xs="8" className={desktopVersion ? 'p-0' : 'pr-0'} >
          <div data-auto="econsult-clinic">{desktopVersion ? eConsult.full_details.mrn : eConsult.full_details.clinic}</div>
        </Col>
      </Row>
      {isCorrectionalFacility && desktopVersion
        ? (
          <Row className="text-primary p-1">
            <Col xs="12" className="pl-0 strong">
              <i className="fa fa-exclamation-circle" />
              <span>This patient is currently in a correctional facility.</span>
            </Col>
          </Row>
        )
        : ''}
      <Row className={desktopVersion ? 'mt-5 pb-3' : 'd-none'}>
        <h4 className="font-weight-bold" >Request Details</h4>
      </Row>
      <Row className={desktopVersion ? 'p-1 provider-info' : 'p-1 provider-info mb-4'} >
        <Col xs={desktopVersion ? '2' : '4'} className="pl-0" >
          <p className="font-weight-lighter m-0">{desktopVersion ? 'Provider Name' : 'Provider'}</p>
        </Col>
        <Col xs="8" data-auto="econsult-provider" className={desktopVersion ? 'p-0' : 'pr-0'} >
          {eConsult.provider}
        </Col>
      </Row>
      {desktopVersion ? (
        <React.Fragment>
          <Row className="p-1 mb-5">
            <Col xs="2" className="pl-0" >
              <p className="font-weight-lighter m-0">Clinic Type</p>
            </Col>
            <Col xs="8" data-auto="econsult-provider" className="p-0" >
              { eConsult.full_details.clinic_type ? `${eConsult.full_details.clinic_type} Facility` : 'N/A'}
            </Col>
          </Row>
          <Row className="p-1">
            <Col xs="2" className="pl-0" >
              <p className="font-weight-lighter m-0">Chief Complaint</p>
            </Col>
            <Col xs="8" data-auto="econsult-provider" className="p-0" >
              <span>{eConsult.full_details.chief_complaint}</span>
              {eConsult.full_details.chief_complaint_details ?
                <span> - {eConsult.full_details.chief_complaint_details}</span> : null}
            </Col>
          </Row>
        </React.Fragment>
      ) : null}
      {eConsult.source === 'aristamd/v2' || (sourceInfo.application === 'request-form' && sourceInfo.version >= 2) ?
        <Row className="p-1 question mb-4">
          <Col xs={desktopVersion ? '2' : 'auto'} className="pl-0">
            <p className="font-weight-lighter m-0 ">
              Clinical Question and Supporting Details
            </p>
          </Col>
          <Col xs={desktopVersion ? '10' : '12'} className="p-0">
            <p className="m-0 ql-editor p-0 econsult-main-question" data-auto="econsult-main-question">
              {parse(eConsult.main_question || '')}
            </p>
          </Col>
        </Row>
        :
        <div>
          <Row className={`p-1 summary${desktopVersion ? ' mb-5' : ''}`}>
            <Col xs={desktopVersion ? '2' : 'auto'} className="pl-0">
              <p className="font-weight-lighter m-0">Issue Summary</p>
            </Col>
            <Col xs={desktopVersion ? '10' : '12'} className="p-0">
              <p className="m-0 ql-editor p-0 econsult-issue-summary" data-auto="econsult-issue-summary">
                {parse(eConsult.issue_summary || '')}
              </p>
            </Col>
          </Row>
          <Row className="p-1 question mb-4">
            <Col xs={desktopVersion ? '2' : 'auto'} className="pl-0">
              <p className="font-weight-lighter m-0 ">{desktopVersion ? '' : 'Main'} Question</p>
            </Col>
            <Col xs={desktopVersion ? '10' : '12'} className="p-0">
              <p className="m-0 ql-editor p-0 econsult-main-question" data-auto="econsult-main-question">
                {parse(eConsult.main_question || '')}
              </p>
            </Col>
          </Row>
        </div>
      }
    </Container>
  );
};

RequestBody.propTypes = {
  eConsult: PropTypes.shape({
    full_details: PropTypes.shape({
      clinic: PropTypes.string,
    }).isRequired,
    issue_summary: PropTypes.string.isRequired,
    main_question: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
  }).isRequired,
  desktopVersion: PropTypes.bool,
};
RequestBody.defaultProps = {
  desktopVersion: false,
};

export default RequestBody;
