import React from 'react';
import PropTypes from 'prop-types';
import {
  dateFormat,
  timeFormat,
  dateBirthFormat,
} from '../../../../utils/formatters/date';


const FullDetailsMobile = ({ eConsultDetails }) => (
  <div className="full-details">
    <h5 className="font-weight-normal">Full Request Details</h5>
    <p className="font-size-large">Patient Information</p>
    <ul className="font-size-large">
      <li data-auth="full-details-date-of-birth">
        Date of Birth - {dateBirthFormat(eConsultDetails.date_of_birth)}
      </li>
      <li data-auth="full-details-aristamd-id">
        AristaMD ID - {eConsultDetails.patient_id}
      </li>
      <li data-auth="full-details-mrn">
        MRN - {eConsultDetails.mrn}
      </li>
    </ul>
    <p className="font-size-large">Clinic Information</p>
    <ul className="font-size-large">
      <li data-auth="full-details-organization">
        Organization - {eConsultDetails.organization}
      </li>
      <li data-auth="full-details-site">
        Site - {eConsultDetails.clinic}
      </li>
      <li data-auth="full-details-notes">
        Notes - {eConsultDetails.notes}
      </li>
    </ul>
    <p className="font-size-large">Assignment Information</p>
    <ul className="font-size-large">
      <li>Service Type - eConsult</li>
      <li data-auth="full-details-eConsultDetails-masked-id">
        eConsult ID - {eConsultDetails.masked_id}
      </li>
      <li data-auth="full-details-assignment-date">
        Assignment Date - {dateFormat(eConsultDetails.assignment_date)} at
        &nbsp;{timeFormat(eConsultDetails.assignment_date)}
      </li>
      <li data-auth="full-details-disposition-due-date">
        Response Deadline - {dateFormat(eConsultDetails.disposition_due_date)} at
        &nbsp;{timeFormat(eConsultDetails.disposition_due_date)}
      </li>
    </ul>
  </div>
);

FullDetailsMobile.propTypes = {
  eConsultDetails: PropTypes.shape({
    date_of_birth: PropTypes.string.isRequired,
    patient_id: PropTypes.number.isRequired,
    mrn: PropTypes.string.isRequired,
    organization: PropTypes.string.isRequired,
    clinic: PropTypes.string.isRequired,
    masked_id: PropTypes.string.isRequired,
    notes: PropTypes.string,
    assignment_date: PropTypes.string.isRequired,
    disposition_due_date: PropTypes.string.isRequired,
  }),
};

FullDetailsMobile.defaultProps = {
  eConsultDetails: {},
};

export default FullDetailsMobile;
