
import store, { history } from '../store';
import { setHeaderTitle } from '../state';
import { useHistory } from "react-router-dom";

// Supported video file types
export const SUPPORTED_VIDEO_TYPES = ['video/mp4', 'video/mov', 'video/quicktime'];
export const findByTestAttr = (component, attr) => component.find(`[data-auto='${attr}']`);
export const setTitle = title => store.dispatch(setHeaderTitle(title));
export const insidePage = (url) => {
    const history = useHistory();
    return history.location.pathname.includes(url);
}
export const findByTextAttributeInDocument = testAttribute => document.body.querySelectorAll(`[data-auto='${testAttribute}']`);
export const sortDates = dates => dates.sort((a, b) => a.unix() - b.unix());
/**
 * Returns true if file type matches one of the supported formats (video/mp4, video/mov, video/quicktime)
 * @param {string} fileType
 * @return {boolean}
 */
 export const isSupportedVideoFile = (fileType) => SUPPORTED_VIDEO_TYPES.includes(fileType);
