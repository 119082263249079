import APICallBase from './APICallBase';

class StarRatingQuestionSets extends APICallBase {
  constructor() {
    super();
    this.url = '/star-rating-question-set';
  }
}

export default () => new StarRatingQuestionSets();
