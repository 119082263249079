import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { timeRemainingColor } from '../../utils/formatters/cssClass';
import { dateAsUTC } from '../../utils/formatters/date';


const TimeRemaining = ({ dueDate, showComplete }) => {
  const renderer = ({ days, hours, minutes }) => {
    const totalHours = (days * 24) + hours;
    let expTimeRemaining = 'Overdue';
    if (totalHours >= 1) {
      expTimeRemaining = ` ${totalHours} ${(totalHours === 1 ? 'hour' : 'hours')}`;
    }
    if (minutes >= 1 && totalHours === 0) {
      expTimeRemaining = ` ${minutes} ${(minutes === 1 ? 'min' : 'mins')}`;
    }
    if (showComplete && expTimeRemaining !== 'Overdue') {
      expTimeRemaining += ' left to respond';
    }
    return expTimeRemaining;
  };

  const color = timeRemainingColor(dateAsUTC(dueDate));
  return (
    <span className={`time-remaining-container${color}`} data-auto="econsult-time-remaining-label">
      <i className="fa fa-history pr-2" />
      <Countdown
        date={dateAsUTC(dueDate)}
        renderer={renderer}
      />
    </span>
  );
};

TimeRemaining.propTypes = {
  dueDate: PropTypes.oneOfType([PropTypes.string, Date]).isRequired,
  showComplete: PropTypes.bool,
};

TimeRemaining.defaultProps = {
  showComplete: false,
};

export default TimeRemaining;
