import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import store from '../../store';
import { configure } from '../../services/axios';
import Session from '../../services/Session';
import Uppy from '../../services/Uppy';
import Epic from '../../services/Epic';
import '../../../assets/images/aristamd_logo.png';
import '../../../assets/images/aristamd_logo_white.svg';
import '../../../assets/images/attachment.png';
import '../../../css/app.scss';
import { assetPreviewUrl } from '../../utils/formatters/attachments';
import MainPage from "../main-page/MainPage";

store.dispatch({
  type: 'CALCULATE_RESPONSIVE_STATE',
});

configure({
  baseURL: `${window.location.protocol}//${window.location.host}/api/mobile/specialist`,
});

// Configure Uppy for specialist mobile
Uppy.init();
// Modifies the original transformation in order to incorporate the preview url
Uppy.modifyPluginOption('XHRUpload', {
  getResponseData: responseText => ({
    id: responseText,
    url: assetPreviewUrl(responseText),
  }),
});
Session.init();
Epic.getCSNFromCookie();

const App = () => (
  <Provider store={store}>
      <BrowserRouter>
          <Switch>
              <Route path="/" component={MainPage} />
          </Switch>
      </BrowserRouter>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById('app'));
