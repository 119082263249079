// Methods for use with econsults
export default class EConsult {
  /**
   * Returns the source information
   * @return object
   */
  static getSourceInfo = function (eConsult) {
    const info = {
      application: null,
      version: null,
    };
    if (eConsult.source) {
      const matches = eConsult.source.match(/aristamd\/(.*)\/v(.*)/);
      if (matches) {
        // destructure matches[1], matches[2] into the info object properties
        [, info.application, info.version] = matches;
      }
    }
    return info;
  };

  /**
   * Returns if eConsult's organization is a correctional facility
   * @param eConsult object
   * @return boolean
   */
  static isCorrectionalFacility = function (eConsult) {
    return eConsult.full_details.clinic_type === 'Corrections';
  };
}
