import { Route, Switch, useParams } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from '../layout/Layout';
import PageContent from '../page-content/PageContent';
import SideDrawer from '../side-drawer/SideDrawer';
import routes from './routes';
import { selectUser, selectUserOrganizationId, selectUserDeviceAuthorized, selectShowIdleModal } from '../../state';
import Setup from '../setup/Setup';
import ConfirmationModal from '../confirmation-modal/ConfirmationModal';
import Session from '../../services/Session';

const modalHeaderContent = 'Inactivity Notice';
const modalBodyContent = (
  <React.Fragment>
    <p>As a security precaution, your AristaMD session will end in {` ${Session.getIdleWarningInMinutes()} `} minutes due to inactivity.
    If you are still working, click OK.
    </p>
  </React.Fragment>);

const modalButtons = {
  ok: {
    text: 'ok',
    onClick: () => {
      Session.hideIdleModal();
    },
    color: 'success',
  },
};

const MainPage = ({
  user,
  organizationId,
  isDeviceAuthorized,
  showConfirmationModal,
}) => {
    const params = useParams();
    return(
      <div>
        {user.id && organizationId && isDeviceAuthorized ? (
            <Layout>
                <SideDrawer params={params} />
                <Switch>
                    {routes.map(route => (
                        <Route
                            exact={route.exact}
                            key={route.path}
                            path={route.path}
                            render={({ match: { params } }) => (
                                <PageContent title={route.title}>
                                    {(typeof route.component === 'function') ? route.component(params) : route.component}
                                </PageContent>)}
                        />
                    ))}
                    <ConfirmationModal
                        modalBodyContent={modalBodyContent}
                        modalHeaderContent={modalHeaderContent}
                        modalButtons={modalButtons}
                        isOpen={showConfirmationModal}
                    />
                </Switch>
            </Layout>
        ) : (<Setup />) }
    </div>
)
}

MainPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  organizationId: PropTypes.number,
  isDeviceAuthorized: PropTypes.bool,
  showConfirmationModal: PropTypes.bool,
};

MainPage.defaultProps = {
  user: {},
  organizationId: -1,
  isDeviceAuthorized: false,
  showConfirmationModal: false,
};

const mapStateToProps = state => ({
  user: selectUser(state),
  organizationId: selectUserOrganizationId(state),
  isDeviceAuthorized: selectUserDeviceAuthorized(state),
  showConfirmationModal: selectShowIdleModal(state),
});

export default connect(mapStateToProps)(MainPage);
