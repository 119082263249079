/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { includes, snakeCase } from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { selectStarRatingQuestionSet } from '../../state';

const StarRating = () => {
  const questionSet = useSelector(state => selectStarRatingQuestionSet(state));
  const [answer, setAnswer] = useState(null);
  const [ratingPrompt, setRatingPrompt] = useState(null);
  const [ratingAnswers, setRatingAnswers] = useState([]);
  const [questionSetName, setQuestionSetName] = useState(null);

  const { register, setValue } = useFormContext();

  /**
   * Once you re-select a new star options the selected answers must be cleared using the question
   * set name
   * @param newQuestionSetName
   */
  const clearQuestionAnswers = (newQuestionSetName) => {
    if (questionSetName !== newQuestionSetName) {
      setQuestionSetName(newQuestionSetName);
      setValue('review_selected_options', []);
      setRatingAnswers([]);
    }
  };

  /**
   * Based on the selected answer selects a new question set
   * @param questionAnswer users star answer
   */
  const defineQuestionSetName = (questionAnswer) => {
    if (includes([1, 2, 3, 4, 5], questionAnswer)) {
      let newQuestionSetName;
      switch (questionAnswer) {
        case 1:
          newQuestionSetName = 'prompt_1';
          break;
        case 2:
          newQuestionSetName = 'prompt_2';
          break;
        case 3:
          newQuestionSetName = 'prompt_3';
          break;
        case 4:
          newQuestionSetName = 'prompt_4';
          break;
        case 5:
          newQuestionSetName = 'prompt_5';
          break;
        default:
          break;
      }
      setRatingPrompt(questionSet[newQuestionSetName]);
      clearQuestionAnswers(newQuestionSetName);
    }
  };

  /**
   * This method is called after user selects a star option
   * @param rating star option value
   */
  const changeRating = (rating) => {
    setAnswer(rating);
    setValue('answer', `${rating}`);
    defineQuestionSetName(rating);
  };

  /**
   * Enables or disables the answer option that user toggles in the view
   * @param ratingAnswer option to toggle
   */
  const togglePromptAnswer = (ratingAnswer) => {
    if (ratingAnswers.includes(ratingAnswer)) {
      ratingAnswers.splice(ratingAnswers.indexOf(ratingAnswer), 1);
    } else {
      ratingAnswers.push(ratingAnswer);
    }
    setValue('review_selected_options', [...ratingAnswers]);
    setRatingAnswers([...ratingAnswers]);
  };

  useEffect(() => {
    // Custom fields hook registration
    register({ name: 'answer' });
    register({ name: 'review_selected_options' });
    return () => {};
  }, [register]);

  return (
    <div className="rating-star" data-auto="star-rating-wrapper">
      <Row className="rating">
        <Col xs="12">
          <h5
            className="text-default font-weight-normal text-center px-5 pt-4"
          >
            How would you rate the quality of the request?
          </h5>
        </Col>
        <Col xs="12" className="d-flex justify-content-center">
          <div className="star-container">
            <input
              id="star5"
              name="answer"
              type="radio"
              checked={answer === 5}
              className="radio-btn hide"
              data-auto="submit-feedback-star-rating-5"
              onChange={(() => changeRating(5))}
            />
            <label
              htmlFor="star5"
              className="font-thin"
              id="star5-label"
              data-auto="submit-feedback-star5-label"
            />
            <input
              id="star4"
              name="answer"
              type="radio"
              checked={answer === 4}
              className="radio-btn hide"
              data-auto="submit-feedback-star-rating-4"
              onChange={(() => changeRating(4))}
            />
            <label
              className="font-thin"
              htmlFor="star4"
              id="star4-label"
              data-auto="submit-feedback-star4-label"
            />
            <input
              id="star3"
              name="answer"
              type="radio"
              checked={answer === 3}
              className="radio-btn hide"
              data-auto="submit-feedback-star-rating-3"
              onChange={(() => changeRating(3))}
            />
            <label
              className="font-thin"
              htmlFor="star3"
              id="star3-label"
              data-auto="submit-feedback-star3-label"
            />
            <input
              id="star2"
              name="answer"
              type="radio"
              checked={answer === 2}
              className="radio-btn hide"
              data-auto="submit-feedback-star-rating-2"
              onChange={(() => changeRating(2))}
            />
            <label
              className="font-thin"
              htmlFor="star2"
              id="star2-label"
              data-auto="submit-feedback-star2-label"
            />
            <input
              id="star1"
              name="answer"
              type="radio"
              checked={answer === 1}
              className="radio-btn hide"
              data-auto="submit-feedback-star-rating-1"
              onChange={(() => changeRating(1))}
            />
            <label
              className="font-thin"
              htmlFor="star1"
              id="star1-label"
              data-auto="submit-feedback-star1-label"
            />
          </div>
        </Col>
      </Row>
      { ratingPrompt ? (
        <Row className="rating-prompt mb-4">
          <Col xs="12" className="rating-question mb-3">
            <h5
              className="text-default font-weight-normal text-center px-5 pt-4"
              data-auto="rating-prompt-question"
            >
              {ratingPrompt.question}
            </h5>
          </Col>
          <Col xs="12" className="rating-question-answers d-flex justify-content-center">
            <div className="answers-container">
              {ratingPrompt.answers.map(promptAnswer => (
                <Button
                  color="primary"
                  onClick={() => togglePromptAnswer(promptAnswer)}
                  key={snakeCase(promptAnswer)}
                  data-auto={`submit-feedback-question-answer-${snakeCase(promptAnswer)}`}
                  className={`mx-1 my-2${ratingAnswers.includes(promptAnswer) ? ' selected' : ''}`}
                >
                  {promptAnswer}
                </Button>
              ))}
            </div>
          </Col>
        </Row>) : null }
    </div>
  );
};

export default StarRating;
