import { put, select } from 'redux-saga/effects';
import {
  selectIsUpdatingEConsult,
  setIsUpdatingEConsult,
} from '../../state';
import EConsults from '../../api-calls/EConsults';

/**
 * Verifies if endpoint is not being called
 * @returns {IterableIterator<*>} next iteration
 */
function* isNotUpdatingEconsult() {
  return yield select(state => !selectIsUpdatingEConsult(state));
}

/**
 * Calls the API in order to update the EConsult
 * @param econsultId what is the id to update
 * @param difference json patch differences
 * @param successCallback callback if request is succeed
 * @param failCallback callback if request fails
 * @returns {IterableIterator<Promise<unknown>>} next iteration
 */
function* doServerCall(econsultId, difference, successCallback, failCallback) {
  try {
    const response = yield EConsults().patch(econsultId, difference);
    successCallback(response);
  } catch (e) {
    failCallback(e);
  }
}

export default function* updateEConsult(action) {
  if (yield* isNotUpdatingEconsult()) {
    yield put(setIsUpdatingEConsult(true));
    yield* doServerCall(
      action.econsultId,
      action.difference,
      action.successCallback,
      action.failCallback,
    );
    yield put(setIsUpdatingEConsult(false));
  }
}
