import React, {useEffect, useMemo, useRef} from 'react';
import { isUndefined, isEmpty } from 'lodash';
import ReactQuill from 'react-quill';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormGroup, Row } from 'reactstrap';
import ErrorText from '../../../common/error-text/ErrorText';
import TimeRemaining from '../../../time-remaining/TimeRemaining';
import { errorColor } from '../../../../utils/formatters/cssClass';

const RecommendationEditor = ({
  name,
  attachmentHandler,
  label,
  desktopVersion,
  eConsult,
  isAttachmentRequired,
}) => {
  const {
    getValues,
    register,
    setValue,
    errors,
    formState,
  } = useFormContext();

  const modules = useMemo(() => ({
    toolbar: {
      container: isAttachmentRequired ? [
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['attachment'],
      ] : [[{ list: 'ordered' }, { list: 'bullet' }]],
      handlers: { attachment: attachmentHandler },
    },
    keyboard: {
      bindings: {
        indent: {
          key: 'tab',
          format: ['list'],
          handler: () => false, // disable indented bullets
        },
      },
    },
  }),[]);

  const quillRef = useRef(null);

  useEffect(() => {
        register({ name: name }, { validate: value => value.replace(/<(.|\n)*?>/g, '').trim().length !== 0 })
  }, []);

  /**
   * We check if the current editor value is the same as the specialist template response
   * to throw an error
   * @param text
   * @returns {""|boolean}
   */
  const isSameAsTemplate = (text) => {
    if (!eConsult.specialist_response_template) {
      return false;
    }
    const specialistResponseTemplate = eConsult.specialist_response_template.replace(/\n/g, ' ');
    const parser = new DOMParser();
    const templateText = parser.parseFromString(specialistResponseTemplate, 'text/html').body.innerText.trim();
    const userText = parser.parseFromString(text, 'text/html').body.innerText.trim();
    return templateText === userText;
  };

  useEffect(() => {
    register({ name }, { validate: value => value.replace(/<(.|\n)*?>/g, '').trim().length !== 0 && !isSameAsTemplate(value) });
  }, []);

  /**
   * If text editor changes without a real value it adds a <p><br></p> string to the response.
   * We add an empty string in that case.
   * @param html
   * @param delta
   * @param origin
   * @param methods
   */
  const handleEditorChange = (html, delta, origin, methods) => {
    const value = methods?.getLength() === 1 ? '' : html;
    setValue(name, value, { shouldValidate: formState.isSubmitted });
  };

  /**
   * If specialist response template is not null we add the template as the default value
   * @returns {string|any}
   */
  const setEditorValue = () => {
    if (eConsult.specialist_response_template) {
      return isEmpty(getValues()[name]) && eConsult.comments.length === 0
        ? eConsult.specialist_response_template
        : getValues()[name];
    }
    return getValues()[name];
  };

  useEffect(() => {
    if (errors && !isUndefined(errors[name])) {
      if (quillRef.current && quillRef.current.value.trim() === '') {
        quillRef.current.editingArea.__quill.focus();
      }
    }
  }, [errors]);

  return (
    <FormGroup>
      <Row className="p-3">
        <div className={desktopVersion ? 'order-1' : 'order-2'}>
          <h5 className={`font-weight-lighter ${errorColor(errors, name)}`}>
            {label}
          </h5>
        </div>
        <div className={desktopVersion ? 'ml-auto order-2' : 'order-1'} data-auto="time-remaining-form-field" >
          <TimeRemaining
            dueDate={eConsult.full_details.disposition_due_date}
            showComplete
          />
        </div>
      </Row>
      <ReactQuill
        theme="snow"
        name={name}
        value={setEditorValue()}
        modules={modules}
        onChange={handleEditorChange}
        formats={[
          'list',
          'indent',
        ]}
        data-auto="form-recommendation-input"
        className={`${!isUndefined(errors[name]) ? 'invalid ' : ''}${isAttachmentRequired ? 'with-attachments' : ''}`}
        ref={quillRef}
      />
      <ErrorText visible={!isUndefined(errors[name])} className="pt-2 pl-1">
        Required
      </ErrorText>
    </FormGroup>
  );
};

RecommendationEditor.propTypes = {
  name: PropTypes.string.isRequired,
  attachmentHandler: PropTypes.func,
  label: PropTypes.string.isRequired,
  isAttachmentRequired: PropTypes.bool,
  desktopVersion: PropTypes.bool,
  eConsult: PropTypes.shape({
    full_details: PropTypes.shape({
        disposition_due_date: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
    }),
    specialist_response_template: PropTypes.string,
  }).isRequired,
};

RecommendationEditor.defaultProps = {
  attachmentHandler: () => {},
  isAttachmentRequired: true,
  desktopVersion: false,
};

export default RecommendationEditor;
