import createReducer from './createReducer';

/** ********************************************
 * constants
 ******************************************** */

export const APP_READY = 'app/APP_READY';

export const PREVIOUS_PATH = 'app/PREVIOUS_PATH';

/** ********************************************
 * reducer
 ******************************************** */

export const initialAppState = {
  isAppReady: false,
  previousPath: '/',
};

export default createReducer(initialAppState, {
  [APP_READY]: (state, { payload }) => ({ isAppReady: payload }),
  [PREVIOUS_PATH]: (state, { payload }) => ({ previousPath: payload }),
});

/** ********************************************
 * actions
 ******************************************** */

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setIsAppReady = bool => ({
  type: APP_READY,
  payload: bool,
});

/**
 *
 * @param {string} string
 * @return {{type: string, payload:*}}
 */
export const setPreviousPath = previousPath => ({
  type: PREVIOUS_PATH,
  payload: previousPath,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {boolean}
 */
export const selectIsAppReady = state => state.app.isAppReady;

/**
 * @param state
 * @return {boolean}
 */
export const selectPreviousPath = state => state.app.previousPath;
