import { select, put } from 'redux-saga/effects';
import { unionWith, differenceWith, isEqual } from 'lodash';
import momentCore from 'moment';
import { extendMoment } from 'moment-range';
import Schedule from '../../api-calls/sp/Schedule';
import BaseParser from '../../utils/fhir-parsers/Base';
import {
  selectPractitioner,
  selectFhirPractitionerSchedule,
  selectFhirPractitionerScheduleDates,
  setFhirPractitionerSchedule,
  setFhirPractitionerScheduleDates,
  setPractitioner,
  setIsSavingSchedule,
} from '../../state';

const moment = extendMoment(momentCore);
/**
 * Check if date marked as available/unavailable already exist as part of the current collection
 * If the date exist proceed to update the status otherwise proceed to add a new date (Slot)
 * @return {void}
 */
function checkScheduleDate(scheduleId, date, status, originalObject) {
  // Set a base date object for new dates
  const baseDateData = {
    resourceType: 'Slot',
    schedule: {
      reference: `Schedule/${scheduleId}`,
    },
  };
  // Try to retrieve the date being modified
  const dateString = moment(date).format('YYYY-MM-DD');
  const currentDate = originalObject.contained.find(slot => slot.start === `${dateString}T00:00:00+00:00`);

  // If the date record already exist. Proceed to update the status
  if (currentDate) {
    currentDate.status = status;
  } else { // Otherwise proceed to create a new record for the date
    const dateData = { ...baseDateData };
    dateData.status = status;
    dateData.start = `${dateString}T00:00:00+00:00`;
    dateData.end = `${dateString}T23:59:59+00:00`;
    originalObject.contained.push(dateData);
  }
}

/**
 * Proceed to prepare (update or add) the schedule dates based in the
 * current and original unavailable dates provided for a calculated range of dates
 * @return {Object}
 */
function prepareScheduleDatesData(
  scheduleId,
  originalUnavailableDates,
  currentUnavailableDates,
  scheduleDates,
  startDate,
  endDate,
) {
  let availableDates = null;

  // Get the date range between start date and end date (valid through month)
  const range = moment.range(startDate, endDate);
  // Generate a dates array for the range given
  const datesArray = Array.from(range.by('days'));
  // Parse the moment dates to Date
  const allDates = datesArray.map(date => date.toDate());

  // Update or Add the current unavailable dates
  currentUnavailableDates.forEach((date) => {
    checkScheduleDate(scheduleId, date, 'busy-unavailable', scheduleDates);
  });

  // Calculate the available dates, excluding the current unavailable dates
  availableDates = differenceWith(allDates, currentUnavailableDates, isEqual);
  // Calculate the removed unavailable dates
  const removedUnavailableDates =
    differenceWith(originalUnavailableDates, currentUnavailableDates, isEqual);
  // Merge the available dates with the removedUnavailableDates
  // To calculate the new available dates
  availableDates = unionWith(availableDates, removedUnavailableDates, isEqual);

  // Update or Add the available dates
  availableDates.forEach((date) => {
    checkScheduleDate(scheduleId, date, 'free', scheduleDates);
  });

  return scheduleDates;
}

export default function* savePractitionerSchedule(action) {
  try {
    const practitioner = yield select(state => selectPractitioner(state));
    const fhirPractitionerSchedule = yield select(state => selectFhirPractitionerSchedule(state));
    const fhirPractitionerScheduleDates =
      yield select(state => selectFhirPractitionerScheduleDates(state));
    yield put(setIsSavingSchedule(true));

    // Update the schedule availability information
    const scheduleUpated = { ...practitioner.schedule };
    scheduleUpated.availabilityValidThrough = action.endDate.format('YYYY-MM-DD');
    scheduleUpated.lastAvailabilitySubmission = moment.utc().format('YYYY-MM-DD HH:mm:ss');

    const baseParser = new BaseParser();

    // Update the practitioner schedule - last availability information
    baseParser.setExtensionValue([
      '/fhir/structureDefinition/scheduleUpdatesInformation',
      'availability_valid_through',
    ], fhirPractitionerSchedule.extension, scheduleUpated.availabilityValidThrough);

    baseParser.setExtensionValue([
      '/fhir/structureDefinition/scheduleUpdatesInformation',
      'last_availability_submission',
    ], fhirPractitionerSchedule.extension, scheduleUpated.lastAvailabilitySubmission);

    baseParser.setExtensionValue([
      '/fhir/structureDefinition/scheduleUpdatesInformation',
      'last_availability_submission_by',
    ], fhirPractitionerSchedule.extension, practitioner.userId);

    const scheduleResponse =
      yield Schedule().put(fhirPractitionerSchedule.id, fhirPractitionerSchedule);

    // Save/update the practitiner schedule dates
    const data = prepareScheduleDatesData(
      fhirPractitionerSchedule.id,
      action.originalUnavailableDates,
      action.currentUnavailableDates,
      fhirPractitionerScheduleDates,
      action.startDate,
      action.endDate,
    );
    const scheduleDatesData = {
      resourceType: 'Bundle',
      type: 'collection',
      contained: data.contained,
    };
    const scheduleDatesResponse =
      yield Schedule().postScheduleDates(fhirPractitionerSchedule.id, scheduleDatesData);

    // Proceed to update the state with the latest schedule information
    yield put(setFhirPractitionerSchedule(scheduleResponse.data));
    yield put(setPractitioner({
      ...practitioner, schedule: scheduleUpated,
    }));
    // Proceed to update the state with the latest schedule dates changes
    yield put(setFhirPractitionerScheduleDates(scheduleDatesResponse.data));
    yield put(setIsSavingSchedule(false));
    action.resolve(true);
  } catch (e) {
    yield put(setIsSavingSchedule(false));
    action.resolve(false);
  }
}
