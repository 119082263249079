import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectHeaderTitle } from '../../state';
import titles from './titles';

/**
 * This function evaluates if the object title exists if it exists it will render
 * component with the default text
 * @param {props} props
 */
const HeaderText = (props) => {
  const renderTitle = (title) => {
    if (title) {
      if (typeof title === 'object' && title.component) {
        const component = titles[title.component];
        if (typeof component === 'function') {
          return component(title.props);
        }
        return component;
      }
      if (typeof title === 'string') {
        return <h5 className="m-0 letter-spacing-fixed">{title}</h5>;
      }
    }
    return null;
  };
  const title = renderTitle(props.title);
  return (
    <div className="header-text" data-auto="page-title">
      {title}
    </div>
  );
};

const mapStateToProps = state => ({
  title: selectHeaderTitle(state),
});

HeaderText.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

HeaderText.defaultProps = {
  title: '',
};

export default withRouter(connect(mapStateToProps)(HeaderText));
