import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import UserPhoto from '../user-photo/UserPhoto';

/**
 * Displays the user name title with its picture, name, and title
 * @param photoUrl user profile photo url
 * @param userTitle what is the user title, provider as default
 * @param firstName user's first name
 * @param lastName user's last name
 * @param suffix user's suffix
 * @param userNameCallbackFunction callback function to indicate how to format user full name
 * @returns {*}
 * @constructor
 */
const UserFullName = ({
  photoUrl,
  userTitle,
  firstName,
  lastName,
  suffix,
  userNameCallbackFunction,
  desktopVersion,
}) => {
  const username = desktopVersion ? `${firstName}. ${lastName}, ${suffix}` : userNameCallbackFunction(firstName, lastName, suffix);
  return (
    <Row className="p-1 response-specialist mt-4">
      <Col className="pb-4">
        <UserPhoto lastName={lastName} firstName={firstName} photoUrl={photoUrl} />
        <div className="d-inline-block align-middle pl-3 w-75">
          <h4 className="text-primary m-0 font-weight-bold letter-spacing-fixed" data-auto="specialist-user-full-name">
            {username}
          </h4>
          <p className="m-0 text-truncate font-weight-lighter" data-auto="specialist-user-full-name-user-title">{userTitle.replace('Specialist', '')}</p>
        </div>
      </Col>
    </Row>
  );
};

UserFullName.propTypes = {
  photoUrl: PropTypes.string,
  userTitle: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  userNameCallbackFunction: PropTypes.func,
  desktopVersion: PropTypes.bool,
};

UserFullName.defaultProps = {
  userTitle: 'Provider',
  photoUrl: null,
  suffix: '',
  desktopVersion: false,
  userNameCallbackFunction: (firstName, lastName, suffix) =>
    `${firstName}. ${lastName}${suffix ? `, ${suffix}` : ''}`,
};

export default UserFullName;
