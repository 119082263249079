import pluralize from 'pluralize';

/**
 * Provides a formatted version of the patient's age
 * @param age patients age
 * @returns {string}
 */
export const formatAge = (ageInDays) => {
  // If the patient is LESS THAN 28 days old, calculate age in DAYS
  if (ageInDays < 28) {
    return `${ageInDays} ${pluralize('day', ageInDays)} old`;
  // If the patient is between 28 days and 3 months, calculate age in WEEKS
  } else if (ageInDays >= 28 && ageInDays < 90) {
    const ageInWeeks = Math.floor(ageInDays / 7);
    return `${ageInWeeks} weeks old`;
  // If the patient between 3 months and 2 years, calculate age in MONTHS
  } else if (ageInDays >= 90 && ageInDays < 730) {
    const ageInMonths = Math.floor(ageInDays / 30);
    return `${ageInMonths} months old`;
  }
  // If the patient is over 2 years, calculate age in YEARS
  const ageInYears = Math.floor(ageInDays / 365);
  return `${ageInYears} years old`;
};

/**
 * Reduces the text for the details in case the character limit is reached
 * @param details
 * @returns {string|*}
 */
export const formatSupportingDetails = (details) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(details, 'text/html');
  return document.body.firstElementChild?.textContent || document.body.textContent;
};

/**
 * Returns a formatted version of the patient's gender
 * @param gender patient gender code
 * @returns {string}
 */
export const formatPatientGender = (gender) => {
  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    case 'X':
      return 'X';
    case 'NB':
      return 'Non-binary';
    case 'O':
      return 'Other';
    default:
      return 'Unknown';
  }
};

/**
 * Builds the specialist name according to the design format <first name letter> <last name>
 *   <suffix>
 * @param firstName
 * @param lastName
 * @param suffix
 * @returns {string}
 */
export const specialistName = (firstName, lastName, suffix) =>
  `${firstName.charAt(0)}. ${lastName}${suffix ? `, ${suffix}` : ''}`;

/**
 * Builds the specialist signature according to the design format <first name> <last name>, <suffix>
 * @param firstName
 * @param lastName
 * @param suffix
 * @returns {string}
 */
export const specialistSignature = (firstName, lastName, suffix) =>
  `${firstName} ${lastName}${suffix ? `, ${suffix}` : ''}`;

/**
 * Builds the initials of a name taking first name and last name letter
 * @param firstName
 * @param lastName
 * @returns {string}
 */
export const userInitials = (firstName, lastName) => `${firstName.charAt(0)}${lastName.charAt(0)}`;

/**
 * Returns the error message base on the browser response
 * @param error
 * @returns {object}
 */
export const errorMessageNotification = (error) => {
  const errorMessage = { title: '', message: '' };
  switch (error.status) {
    case 403:
      errorMessage.title = 'This request is no longer assigned to you.';
      errorMessage.message = 'Go back to your request queue to find another request.';
      break;
    default:
      errorMessage.title = 'Sorry, we weren’t able to find that request.';
      errorMessage.message = 'Contact <a href="mailto:support@aristamd.com">support@aristamd.com</a> if this issue continues.';
      break;
  }
  return errorMessage;
};
