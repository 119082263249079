import axios from 'axios';
import store from '../store';
import { fetchPractitioner } from '../state';

// Creates a new instance for sp api calls
const spAxios = axios.create({
  baseURL: '/sp-api/v1',
});

// Flag to check if there is sp api authentication
let isSpAuthenticated = false;

/**
 * Performs a SP Authentication process only if flag is false
 */
export const authenticate = () => {
  if (!isSpAuthenticated) {
    isSpAuthenticated = true;
    axios.get('/oauth/jwt/specialty-platform') // Call core bypass endpoint
      .then(({ data }) => spAxios.get('/generate-token', { params: { jwt: data.token } })).then(({ data }) => {
        // Only updates the authentication header for instance
        spAxios.interceptors.request.use((config) => {
          const newConfig = { ...config };
          newConfig.headers.Authorization = `Bearer ${data.token}`;
          return newConfig;
        });
        store.dispatch(fetchPractitioner());
      });
  }
};

export default () => spAxios;
