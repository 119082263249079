import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = ({
  desktopVersion, toggle, children, helpIconOnly,
}) => (
  <div className={`modal-header menu-title${desktopVersion ? ' p-1' : ''}`} data-auto="modal-header-container">
    <button
      type="button"
      onClick={toggle}
      className="close my-auto mx-3 p-0"
      aria-label="Close"
      data-auto="modal-close-btn"
    >
      <i className="fa fa-times" aria-hidden="true" />
    </button>
    <div
      className={`container my-auto${desktopVersion ? ' mr-auto' : ''}`}
      data-auto="modal-header-text"
    >
      {children}
    </div>
  </div>
);

ModalHeader.propTypes = {
  desktopVersion: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  helpIconOnly: PropTypes.bool,
};

ModalHeader.defaultProps = {
  desktopVersion: false,
  helpIconOnly: false,
};

export default ModalHeader;
