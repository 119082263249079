import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { use100vh } from 'react-div-100vh';
import store from '../../store';
import { selectEConsults, selectHasMore, selectIsFetchingEConsults, selectEConsultsError } from '../../state';
import { setTitle } from '../../utils';
import Queue from '../queue/Queue';
import EConsultQueueItem from '../queue-items/econsult-queue-item/EConsultQueueItem';
import Session from '../../services/Session';

const EConsultsQueue = (props) => {
  // Stores the current applied status group inside component
  let currentStatusGroup;
  const queueHeight = `${use100vh() - 80}px`;

  /**
   * Calls fetchEconsults callback in order to fetch a set of econsults
   * @param queueParams saga parameters
   * @returns {*}
   */
  const fetchData = (queueParams = { }) => store.dispatch({
    type: 'econsultsSaga/FETCH_ECONSULTS',
    ...queueParams,
  });

  /**
   * This function will be called when the user pulls the queue from top
   * @param completed in case the queue was completely iterated
   * @returns {*}
   */
  const scrollToTopCallback = completed => fetchData({
    completed,
    reset: true,
    statusGroup: props.statusGroup,
  });

  /**
   * This function will be called when the user scrolls the queue from the bottom
   * @param completed in case the queue was completely iterated
   * @returns {*}
   */
  const scrollToBottomCallback = completed =>
    fetchData({ completed, statusGroup: props.statusGroup });

  /**
   * Specifies how the queue items must be rendered
   * @param item queue item data
   * @returns {*}
   */
  const renderCallback = item => (<EConsultQueueItem item={item} />);

  useEffect(() => {
    if (Session.userId) {
      if (!props.eConsults.length || props.statusGroup !== currentStatusGroup) {
        fetchData({ reset: true, statusGroup: props.statusGroup });
        currentStatusGroup = props.statusGroup;
      }
      setTitle(props.statusGroup === 'SpecialistMobileAll' ? 'Request History' : 'My Requests');
      if (props.eConsultsError) {
        const queueName = `${props.statusGroup === 'SpecialistMobileAll' ? 'Request-History' : 'My-Request'}`;
      }
    }
    return () => {};
  }, []);

  return (<Queue
    dataSource={props.eConsults}
    handleScrollToTop={scrollToTopCallback}
    handleScrollToBottom={scrollToBottomCallback}
    handleRenderItem={renderCallback}
    isLoadingData={props.isFetchingEConsults}
    hasMore={props.hasMore}
    queueHeight={queueHeight}
    emptyItem={
      <div className="text-container">
        <h4 className="text-center">Your inbox is empty</h4>
        <p className="text-center">
          New requests that require your response will appear here
        </p>
      </div>
    }
  />);
};


EConsultsQueue.propTypes = {
  eConsults: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetchingEConsults: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  statusGroup: PropTypes.string.isRequired,
  eConsultsError: PropTypes.string,
};
EConsultsQueue.defaultProps = {
  eConsultsError: null,
};

const mapStateToProps = state => ({
  eConsults: selectEConsults(state),
  isFetchingEConsults: selectIsFetchingEConsults(state),
  hasMore: selectHasMore(state),
  eConsultsError: selectEConsultsError(state),
});


export default withRouter(connect(mapStateToProps)(EConsultsQueue));
