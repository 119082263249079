import Cookies from 'js-cookie';

// Methods to save EPIC CSN number in session and retrieve it when needed
export default class Epic {
  /**
   * When an Epic specialist goes into the app
   * A short-lived cookie holding a CSN can be found
   * The idea is to save the CSN in session before cookie expires
   * so it can be finally saved in the econsult
   * when the specialist dispositions it
   * @return void
   */
  static getCSNFromCookie() {
    const epicCSN = Cookies.get('app_smart_csn');
    if (epicCSN) {
      sessionStorage.setItem('csn', epicCSN);
    }
  }

  /**
   * Returns the CSN (if any) in sessionStorage
   * @return string or undefined
   */
  static getCSN() {
    return sessionStorage.getItem('csn');
  }

  /**
   * Remove CSN from session so it is not saved
   * in any other econsult
   * @return void
   */
  static forgetCSN() {
    sessionStorage.removeItem('csn');
  }
}
