import _ from 'lodash';
import moment from 'moment';

/**
 * applies the error-color in case there is an error for an specified attribute
 * @param {{}} errors errors container
 * @param {string} attribute attribute name
 * @returns {string} css classs
 */
export const errorColor = (errors, attribute) =>
  (!_.isUndefined(errors[attribute]) ? ' color-error' : '');

/**
 * Returns the time remaining color according to the due date
 * @param {string} date due date
 * @returns {string} css class according to due date
 */
export const timeRemainingColor = (date) => {
  const hoursRemaining = moment(date).diff(moment(), 'hours');
  if (hoursRemaining >= 5) {
    return ' level-3';
  }
  if (hoursRemaining >= 2) {
    return ' level-2';
  }
  if (hoursRemaining >= 0) {
    return ' level-1';
  }
  return ' level-0';
};

/**
 * Applies is-invalid class if condition is fulfilled
 * @param {boolean} condition condition to fulfill
 * @returns {string} css class
 */
export const isInvalid = condition => (condition ? ' is-invalid' : '');

/**
 * Applies is-disabled class if condition is fulfilled
 * @param {boolean} condition condition to fulfill
 * @returns {string} css class
 */
export const isDisabled = condition => (condition ? ' is-disabled' : '');
