import { takeEvery } from 'redux-saga/effects';
import fetchStarRatingQuestionSet from './fetchStarRatingQuestionSet';

const setSagaNamespace = sagaName => `starRatingQuestionSetSaga/${sagaName}`;

export default function* starRatingQuestionSetSaga() {
  return [
    yield takeEvery(setSagaNamespace('FETCH_STAR_RATING_QUESTION_SET'), fetchStarRatingQuestionSet),
  ];
}
