import { put } from 'redux-saga/effects';
import { setIsFetchingPractitionerSchedule, setPractitioner, setPractitionerScheduleError } from '../../state';
import Practitioner from '../../api-calls/sp/Practitioner';

export default function* fetchPractitioner() {
  try {
    const response = yield Practitioner().getCurrentPractitioner();
    yield put(setPractitioner(response));
  } catch (e) {
    yield put(setPractitioner(null));
    yield put(setPractitionerScheduleError(true));
    yield put(setIsFetchingPractitionerSchedule(false));
  }
}
