import moment from 'moment-timezone';

/**
 * Creates a moment date based on a carbon object
 * @param date carbon date
 * @returns {*}
 */
export const dateFromCarbon = date => moment.utc(date).tz(moment.tz.guess());

/**
 * Formats the date using format MM/dd/YYYY
 * @param date carbon date
 * @returns {*}
 */
export const dateFormat = date => dateFromCarbon(date).format('L');

/**
 * Formats the date using format MM/dd/YYYY
 * @param date carbon date
 * @returns {*}
 */
export const dateWithoutTZFormat = date => moment(date).format('L');

/**
 * Formats a birth date using format MM/dd/YYYY
 * @param date birth date as string
 * @returns {*}
 */
export const dateBirthFormat = date => moment(date).format('LL');

/**
 * Formats the date using format hh:mm:ss
 * @param date carbon date
 * @returns {*}
 */
export const timeFormat = date => dateFromCarbon(date).format('LT');

/**
 * Formats the date using format MM/DD/YYYY at hh:mm:ss TZ
 * @param date carbon date
 * @returns {*}
 */
export const dateWithTimezoneFormat = (date, timezone = moment.tz.guess()) =>
  dateFromCarbon(date).tz(timezone).format('L [at] h:mma z');

/**
 * Displays the last update date or time according with the past days
 * @param date carbon date
 * @returns {string}
 */
export const lastUpdate = (date) => {
  const dateFormatted = dateFromCarbon(date);
  if (moment(dateFormatted).diff(new Date(), 'days') === 0) {
    return moment(dateFormatted).format('h:mm a');
  }
  return moment(dateFormatted).format('MMM DD');
};

/**
 * formats a date object or string into a date object in UTC format
 * @param date date as string or date object
 * @returns {Date}
 */
export const dateAsUTC = date => moment.utc(date).toDate();

/**
 * Generates a date range based of a set of dates
 * @param dates set
 * @param format how the range should be formated
 * @return {string}
 */
export const monthRange = (dates, format = 'MMMM YYYY') => {
  if (dates.length === 0) {
    return `${moment().format(format)}`;
  }
  const start = dates[0].format(format);
  const end = dates.slice(-1)[0].format(format);
  return `${start} - ${end}`;
};

/**
 * Returns a moment instance form a string in utc format
 * @param strDate date as string
 * @return {*} moment instance in utc format
 */
export const momentTzUtc = strDate => moment.tz(strDate, 'utc');

/**
 * Generates a relative date in order to be compared
 * 1. Removes any time or timezone leaving date format as YYYY-MM-DD
 * 2. Sets the date as the start of the day
 * 3. Adds 12 hours in order to leave the day at mid-day
 * @param date string date to parse
 * @param parser parser callback function (Default YYYY-MM-DDTHH:ss+00:000)
 * @return {moment.Moment} moment instance as a relative date
 */
export const relativeDate = (date, parser = d => d.split('T')[0]) =>
  moment(parser(date)).add(12, 'hours');
