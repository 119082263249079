import _ from 'lodash';

/**
 * Returns the formatted URL for the asset to visualize the attachment
 * @param {string} assetId
 * @return {string} formatted url
 */
 export const assetUrl = (assetId) => `/assets/${assetId}`;

/**
 * Returns the formatted URL for the thumbnails
 * @param {string} assetId
 * @return {string} formated url
 */

export const assetThumbnailUrl = assetId => `/assets/${assetId}/thumbnail`;

/**
 * Returns the formatted URL for the iframe to visualize the attachment
 * @param {string} assetId
 * @return {string} formated url
 */

export const assetPreviewUrl = assetId => `/pdf-viewer/web/viewer.html?file=../../assets/${assetId}/preview`;

/**
 * Retuns a boolean in case the format is present in the array
 * @param {string} assetNmae name
 * @return {boolean}
 */
export const verifyFormat = (asset) => {
  const findFormat = _.split(_.toLower(asset.name), '.');
  const formats = ['jpeg', 'jpg', 'png', 'gif', 'tif', 'tiff', 'bmp', 'pdf', 'mp4', 'mov', 'avi', 'wmv'];
  // if the document is from the print queue we need to use the mime_type instead of the name
  const findFormatMimeType = _.split(_.toLower(asset.mime_type), '/');
  const isVerified = !!formats.find(k => k === findFormat[findFormat.length - 1]) ||
  !!formats.find(k => k === findFormatMimeType[findFormatMimeType.length - 1]);
  return isVerified;
};

/**
 * Returns the attachment name formatted
 * @param {string} assetName name of the asset
 * @return {string} name formatted
 */
export const formattedName = (assetName) => {
  const fileNameArray = _.split(_.toLower(assetName), '.');
  fileNameArray.pop();
  return fileNameArray;
};

/**
 * Returns the formatted URL for the iframe to visualize a previous econsult pdf
 * @param {string} assetId
 * @return {string} formated url
 */
export const previousEConsultUrl = eConsultId => `/pdf-viewer/web/viewer.html?file=../../assets/print/econsult/${eConsultId}`;

/**
 * Returns the formatted URL for the thumbnails for econsult pdfs
 * @param {string} assetId
 * @return {string} formated url
 */
export const eConsultThumbnailUrl = eConsultId => `/assets/print/econsult/${eConsultId}?getThumbnail=true`;
