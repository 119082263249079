import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import ErrorText from '../error-text/ErrorText';

const ErrorCountAlert = ({ errors }) => {
  /**
   * Returns what is the found errors count
   * @param {{}} errorObject react form hook error object
   * @returns {number} error count
   */
  const errorCount = errorObject => Object.keys(errorObject).length;

  /**
   * Returns a message according to the error count
   * @param {int} count error count
   * @returns {string} error message
   */
  const formatMessage = count => (count > 1 ? `${count} errors were found.` : '1 error was found.');

  const errorMessage = formatMessage(errorCount(errors));

  return (errorCount(errors) > 0 ?
    <Alert color="danger" className="error-count-alert" data-auto="error-count-alert">
      <ErrorText>
        <span>&nbsp;&nbsp;{errorMessage}</span>
      </ErrorText>
    </Alert> : null
  );
};

ErrorCountAlert.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

ErrorCountAlert.defaultProps = {
  errors: {},
};

export default ErrorCountAlert;
