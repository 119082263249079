import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectBrowserViewport, setIsSideDrawerOpen } from '../../../state';
import store from '../../../store';


const pageContentStyle = (url, browserViewport) => {
  if (url.includes('/') && browserViewport.greaterThan.medium) {
    store.dispatch(setIsSideDrawerOpen(true));
  }
};

const BackButton = ({ url, browserViewport }) => (
  <div className="back-button-container pr-3">
    <NavLink
      className="link back-button"
      to={url}
      onClick={() => { pageContentStyle(url, browserViewport); }}
      data-auto="back-link"
    >
      <i className="fa fa-angle-left" />
    </NavLink>
  </div>
);

BackButton.propTypes = {
  url: PropTypes.string.isRequired,
  browserViewport: PropTypes.shape({
    greaterThan: PropTypes.shape({
      medium: PropTypes.bool,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    browserViewport: selectBrowserViewport(state),
  };
}

export default connect(mapStateToProps)(BackButton);
