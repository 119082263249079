import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { DashboardModal } from '@uppy/react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Uppy from '../../../../services/Uppy';
import EconsultModalAttachment from '../../econsult-attachment-modal/EconsultModalAttachment';
import { assetThumbnailUrl, assetPreviewUrl } from '../../../../utils/formatters/attachments';

const ResponseAttachments = ({
  eConsult,
  isAttachmentModalOpen,
  handleClose,
  desktopVersion,
}) => {
  const { getValues, setValue, register } = useFormContext();
  register({ name: 'disposition_attachments', value: [] });

  /**
   * Completed event: Once all the files are uploaded successfully
   * form is updated in order to incorporate the new attachments
   */
  Uppy.setEvent('complete', (result) => {
    const attachments = result.successful.map((file) => {
      // Appends the thumbnail for uploaded file
      Uppy.getInstance().setFileState(file.id, {
        preview: `${assetThumbnailUrl(file.response.body.id)}?width=200`,
      });
      // Creates the file structure to be added to the eConsult
      return {
        id: file.response.body.id,
        name: file.name,
        size: file.size, // Required to recreate the uploaded file list
        type: file.type, // Required to recreate the uploaded file list
        lastModified: file.data.lastModified, // Required to recreate the uploaded file list
      };
    });
    // Updates the field with new attachments
    const currentAttachments = getValues().disposition_attachments;
    setValue('disposition_attachments', [...currentAttachments, ...attachments]);
  });

  /**
   * File Removed event: Once an attachment is removed it must be removed from the eConsult
   */
  Uppy.setEvent('file-removed', (file) => {
    if (file.response.body && getValues().disposition_attachments) {
      setValue('disposition_attachments', getValues().disposition_attachments
        .filter(attachments => attachments.id !== file.response.body.id));
    }
  });

  /**
   * Adds an already uploaded file to the Uppy core instance in order to be handled
   * @param attachment uploaded attachment
   */
  const addUploadedFile = attachment => Uppy.addFile({
    name: attachment.name,
    data: new File(
      [(new Uint8Array(attachment.size)).buffer], // Fake file buffer to set the file size
      attachment.name,
      { lastModified: attachment.lastModified, type: attachment.type },
    ),
  }, {
    // Fakes the upload to indicate file was already uploaded
    progress: { uploadComplete: true, uploadStarted: true },
    // Appends a server response that contains the id
    response: { body: { id: attachment.id } },
    preview: `${assetThumbnailUrl(attachment.id)}?width=200`,
    uploadURL: assetPreviewUrl(attachment.id),
  });

  useEffect(() => {
    if (eConsult) {
      (eConsult.disposition_attachments || []).forEach(addUploadedFile);
    }
    return () => {};
  }, [eConsult]);

  return (
    <div>
      <DashboardModal
        uppy={Uppy.getInstance()}
        open={isAttachmentModalOpen}
        plugins={['Webcam']}
        showRemoveButtonAfterComplete
        closeModalOnClickOutside
        onRequestClose={handleClose}
      />
      <Container className="attachments-container p-0">
        { getValues().disposition_attachments.map(attachment => (
          <EconsultModalAttachment
            key={`attachment-container-${attachment.id}`}
            attachment={attachment}
            eConsult={eConsult}
            desktopVersion={desktopVersion}
          />
        ))}
      </Container>
    </div>
  );
};

ResponseAttachments.propTypes = {
  eConsult: PropTypes.shape({
    id: PropTypes.number,
    disposition_attachments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      lastModified: PropTypes.number,
    })),
  }).isRequired,
  isAttachmentModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  desktopVersion: PropTypes.bool,
};

ResponseAttachments.defaultProps = {
  desktopVersion: false,
};

export default ResponseAttachments;
