import APICallBase from './APICallBase';

class Specialties extends APICallBase {
  constructor() {
    super();
    this.url = '/specialties/withAvailablePanelists';
  }

  /**
   * Get the specialties required for another recommended specialty based on
   * patient's age and organization id without including the current specialty
   * @param orgId
   * @param patientAge
   * @param currentSpecialty specialty to ignore
   * @return {Promise} endpoint call promise
   */
  getRecommendedSpecialties(orgId, patientAge, currentSpecialty) {
    return this.get({
      organizationId: orgId,
    }, patientAge >= 18 ? 'non-pediatrics' : 'pediatrics')
      .call().then(({ data }) => ({
        data: data.map((specialty) => {
          const newSpecialty = { ...specialty };
          newSpecialty.subspecialties = specialty.subspecialties
            .filter(subSpecialty => subSpecialty.display_name !== currentSpecialty);
          if (newSpecialty.subspecialties.length === 0) {
            return null;
          }
          return newSpecialty;
        }).filter(Boolean),
      }), error => error);
  }
}

export default () => new Specialties();

