import Base from './Base';
import Slot from './Slot';

class Bundle extends Base {
  className = 'Bundle';
  allowedBundleClasses={
    Slot: resource => Slot(resource),
  };
  parseData() {
    return this.resource.contained.map((resource) => {
      const parser = this.allowedBundleClasses[resource.resourceType];
      return parser ? parser(resource).transform() : null;
    }).filter(n => n);
  }
}

export default fhirResource => new Bundle(fhirResource);
